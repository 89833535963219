import i18next from 'i18next';
import bigOldBoringdictionary from './dictionaries/dicionary';

import { languages } from '../context/LanguageContext';

const loadResources = async () => {
  await i18next.addResourceBundle(
    languages.pt.standardCode,
    'app',
    bigOldBoringdictionary[languages.pt.dictionaryKey],
    true,
    true
  );
  await i18next.addResourceBundle(
    languages.en.standardCode,
    'app',
    bigOldBoringdictionary[languages.en.dictionaryKey],
    true,
    true
  );
  await i18next.addResourceBundle(
    languages.fr.standardCode,
    'app',
    bigOldBoringdictionary[languages.fr.dictionaryKey],
    true,
    true
  );
  await i18next.addResourceBundle(
    languages.es.standardCode,
    'app',
    bigOldBoringdictionary[languages.es.dictionaryKey],
    true,
    true
  );
  await i18next.addResourceBundle(
    languages.zh.standardCode,
    'app',
    bigOldBoringdictionary[languages.zh.dictionaryKey],
    true,
    true
  );
};

export default loadResources;
