import React from 'react';
import { useTranslation } from 'react-i18next';
import heroImage from '../../img/nifrequest/heroImage.png';
import { ReactComponent as HeroPic1 } from '../../img/nifrequest/hero_pic1.svg';
import { ReactComponent as HeroPic2 } from '../../img/nifrequest/hero_pic2.svg';
import { ReactComponent as HeroPic3 } from '../../img/nifrequest/hero_pic3.svg';
import { Link } from 'react-router-dom';
import './Dashboard.scss';

const HeroNifRequest = () => {
  // eslint-disable-next-line no-unused-vars
  const { t } = useTranslation();
  return (
    <div className="flex w-full h-auto p-12 justify-center items-center bg-gray-100">
      <div className="flex w-7/8 lg:w-full h-auto flex-col lg:flex-row justify-center items-center bg-gray-100">
        <DescriptionSection />
        <ImageSection />
      </div>
    </div>
  );

  function DescriptionSection() {
    return (
      <div className="flex lg:w-1/3   w-full h-full  bg-gray-100">
        <div className="flex flex-col  w-full h-full  justify-between  bg-white  px-8  py-6 text-finblue">
          <div className=" text-4xl py-6">APPLY FOR TIN</div>

          <div className=" text-lg leading-normal pb-4">
            The tax identification number (TIN) is for all individuals who want to open a bank
            account in Portugal, buy a house or practice any other transactions in Portuguese
            territory. By requesting the TIN, you will also have acess to the Tax Representation
            service. Any non-resident individual or company resident outside the EU must appoint a
            Fiscal Representative by law. This service has a duration of 1 year.
          </div>
          <div className="flex w-full h-auto  pb-8">
            <div className="flex flex-row justify-around flex-wrap">
              <div className="flex flex-col justify-start w-1/3 h-auto items-center">
                <HeroPic1 className={'w-32 h-32 p-8'} />
                <div className="flex flex-col justify-center items-center text-center">
                  <div className="flex font-extrabold text-2xl pb-1">Easy</div> to submit, no
                  complications, just one click
                </div>
              </div>
              <div className="flex flex-col justify-start w-1/3 h-auto items-center">
                <HeroPic2 className={'w-32 h-32 p-8'} />
                <div className="flex flex-col justify-center items-center text-center">
                  <div className="flex font-extrabold text-2xl pb-1">Fast</div> simply fill in our
                  online form to start the process.
                </div>
              </div>
              <div className="flex flex-col justify-start w-1/3 h-auto items-center">
                <HeroPic3 className={'w-32 h-32 p-8'} />
                <div className="flex flex-col justify-center items-center text-center">
                  <div className="flex font-extrabold text-2xl pb-1">Secure</div> payment and you
                  can see the status of your applications
                </div>
              </div>
            </div>
          </div>

          <div className="flex w-full justify-center mt-6 mb-6">
            <Link to={'/app/nifrequest'}>
              <div
                // onClick={() => {
                //   toast.info('NIF applications will be published soon. ');
                // }}
                className="flex  w-60  text-2xl h-14 text-white bg-finblue justify-center items-center rounded-xl cursor-pointer transition transform hover:scale-105"
              >
                <div>APPLY</div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    );
  }

  function ImageSection() {
    return (
      <div className="flex lg:w-1/3 w-auto h-full max-h-160 lg:max-h-192 items-start justify-start overflow-hidden bg-gray-100">
        <img
          src={heroImage}
          className={'flex w-full h-full justify-start bg-gray-100 object-cover object-top '}
          alt="heroImage"
        />
      </div>
    );
  }
};

export default HeroNifRequest;
