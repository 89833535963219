import { ArrowLeftIcon } from '@heroicons/react/outline';
import React from 'react';
//import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import Footer from '../../components/Footer';
import BlueHeader from '../../components/Header/BlueHeader';
//import BusinessContext from '../../context/BusinessContextBase';
import { ReactComponent as DescIcon } from '../../img/nifrequest/descIcon.svg';
import { ReactComponent as NifIntro } from '../../img/nifrequest/intro_img.svg';

const RequestNifHome = () => {
  //const { t } = useTranslation();

  React.useEffect(() => {}, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="w-full h-full flex flex-col justify-between items-center bg-gray-100  overflow-scroll">
      <div className="w-full h-auto flex flex-col">
        <BlueHeader title="Account" subtitle="In the palm of your hand"></BlueHeader>
        <Hero />
      </div>
      <MyApplications />
      <Footer></Footer>
    </div>
  );
};

const Hero = () => {
  const history = useHistory();

  return (
    <div
      id="nifrequest"
      className="w-full h-auto justify-center items-start flex-wrap flex flex-line gap-12 p-12  bg-gray-100"
    >
      <div className="w-6/7  h-auto min-w-100  lg:w-2/7 flex flex-col">
        <div
          className="relative w-16 h-16 p-1 mb-8 rounded-full border-2 border-finblue cursor-pointer transform transition hover:scale-105"
          onClick={() => {
            history.push('/app');
          }}
        >
          <ArrowLeftIcon className="w-full h-full stroke-current text-finblue stroke-1"></ArrowLeftIcon>
        </div>
        <div className="flex w-full h-full  flex-col   bg-white rounded-3xl overflow-hidden items-center justify-center">
          <div className="flex h-20 w-full bg-finblue justify-center items-center p-2">
            <DescIcon className="h-full w-auto" />
          </div>
          <div className="p-6">
            <div className="text-4xl font-bold text-finblue">TIN</div>
            <div className="text-2xl  font-normal  py-6 ">
              lso known as the Portuguese individual tax identification number, NIF is the
              taxpayer’s personal number for tax purposes in Portugal such as opening a bank
              account, signing up for utilities, and buying or renting real estate, among others.
            </div>
            <div className="text-2xl py-6 ">
              Get your Portuguese TIN without complications and remotely from this platform! Simple,
              easy, and safe.
            </div>
          </div>

          <Link
            to={'/app/nifrequestform'}
            className="flex h-20 w-1/2 bg-finblue justify-center items-center rounded-full my-6 cursor-pointer transform transition hover:scale-105"
          >
            <div className="text-white text-xl">Get the TIN</div>
          </Link>
        </div>
      </div>
      <div className="relative  w-2/3 h-3/7 lg:w-3/7 lg:h-full lg:max-h-192 rounded-3xl overflow-auto ">
        <NifIntro className=" max-w-full max-h-full object-contain" />
      </div>
    </div>
  );
};

const MyApplications = () => {
  return (
    <div className="flex bottom-6 right-6 ">
      <Link
        to={'nifapplications'}
        className="flex relative h-20 w-60 bg-finblue justify-center items-center rounded-full my-6 mx-1 cursor-pointer transform transition hover:scale-105"
      >
        <div className="text-white text-xl">My applications</div>
      </Link>
    </div>
  );
};

export default RequestNifHome;
