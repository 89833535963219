import React from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';

import BlueHeader from '../../components/Header/BlueHeader';
import Footer from '../../components/Footer';
import settingsRoutes from '../../routes/settings';

import './Settings.scss';

const accountPath = '/app/settings/account';

const Settings = () => {
  console.log('in settings page');
  return (
    <div className="w-full h-full flex-grow flex flex-col items-center">
      <BlueHeader title="Account" subtitle="In the palm of your hand"></BlueHeader>

      <div
        id="admin-settings"
        className="w-full md:w-10/12 h-full flex-grow flex flex-col items-center"
      >
        <Switch>
          {settingsRoutes?.map((route) => {
            return (
              <Route
                key={route.path}
                exact={route.exact}
                path={route.path}
                render={() => <route.component></route.component>}
              />
            );
          })}
          <Route render={() => <Redirect to={accountPath}></Redirect>} />
        </Switch>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Settings;
