import React, { Component } from 'react';
import { Switch } from 'react-router-dom';
import AppContext from '../../context/AppContextBase';

import { BusinessContextProvider } from '../../context/BusinessContext';
import PublicRoute from '../../components/RoutePublic';
import Lost from '../../components/Lost';

import routes from '../../routes/admin';

import './index.scss';

class AdminHome extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = { posts: [], categories: [], user: null };
  }

  async componentDidMount() {
    const user = await this.context.currentUser();
    this.setState({ user });
  }

  render() {
    return (
      <BusinessContextProvider>
        <div
          id="admin"
          className="relative flex flex-col justify-start items-center w-full h-auto bg-gray-50"
        >
          <div className="w-full min-h-screen flex flex-col items-center">
            <Switch>
              {routes?.map((route) => {
                return (
                  <PublicRoute
                    key={route.path}
                    exact={route.exact}
                    path={route.path}
                    component={route.component}
                    render={
                      route?.render ? (props) => route.render({ ...props, ...this.state }) : null
                    }
                  ></PublicRoute>
                );
              })}
              <PublicRoute component={Lost} />
            </Switch>
          </div>
        </div>
      </BusinessContextProvider>
    );
  }
}

export default AdminHome;
