import React from 'react';

import { useTranslation } from 'react-i18next';

import BusinessContext from '../../context/BusinessContextBase';
import BlueHeader from '../../components/Header/BlueHeader';
import Footer from '../../components/Footer';
import CodePreview from '../../components/CodePreview';

import Ring from '../../components/Ring';

import './DocumentsPersonal.scss';
import ControlBar from './ControlBar';
import DocumentGrid from './DocumentGrid';

const DocumentList = () => {
  const businessContext = React.useContext(BusinessContext);
  const [self, setSelf] = React.useState(businessContext.self);
  const [documents, setDocuments] = React.useState(self?.documents);

  const { t } = useTranslation();

  React.useEffect(() => {
    businessContext.fetchSelf();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    setSelf(businessContext.self);
    setDocuments(businessContext.self?.documents);
    console.log(businessContext.self?.documents);
  }, [businessContext.self]);

  return (
    <div className="w-full h-full flex-grow flex flex-col justify-between items-center">
      <BlueHeader title="Account" subtitle="In the palm of your hand"></BlueHeader>
      <div id="personal" className="w-10/12 h-auto flex-grow flex flex-col py-12">
        <div className="w-full flex flex-col">
          <div className="w-full flex flex-wrap content-start gap-4 justify-between items-center pb-4">
            <h1 className="text-3xl text-finblue">{t('documents')}</h1>

            <ControlBar
              documents={documents}
              setDocuments={setDocuments}
              resetDocuments={() => setDocuments(self?.documents)}
            ></ControlBar>
          </div>
          <div className="w-full h-0 mb-4 border-b-2 border-gray-200"></div>
          {documents?.length > 0 ? (
            <div className="w-full flex flex-wrap items-center pb-12">
              <DocumentGrid documents={documents} setDocuments={setDocuments}></DocumentGrid>
            </div>
          ) : documents?.length === 0 ? (
            <div className="w-full flex flex-wrap items-center pb-12">
              <div className="text-gray-500">{t('no_documents')}</div>
            </div>
          ) : (
            <Ring></Ring>
          )}
        </div>
        <CodePreview className="w-10/12 pb-4" post={self} label="JSON"></CodePreview>
      </div>

      <Footer></Footer>
    </div>
  );
};

export default DocumentList;
