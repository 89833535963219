import React from 'react';
import LanguageContext from '../../context/LanguageContextBase';

import { ReactComponent as LogoBig } from '../../img/logo_big.svg';
import { ReactComponent as OrangeShape } from '../../img/shape_orange_big.svg';

import './Header.scss';

const OrangeHeader = () => {
  const languageContext = React.useContext(LanguageContext);
  return (
    <div
      id="orange-header"
      className={`relative top-0 max-h-1/2 w-full flex flex-col justify-start items-center overflow-x-hidden overflow-y-visible z-0`}
    >
      {process.env.NODE_ENV === 'development' && (
        <div className="absolute top-2 left-2 text-white z-30">
          [dev] lang: {languageContext.language}
        </div>
      )}
      <div className="shape-container relative top-0 h-auto">
        <OrangeShape id="orange-shape" className="w-full h-auto"></OrangeShape>
      </div>
      <LogoBig className="absolute h-auto w-5/6 sm:w-2/3 md:w-1/3 top-10"></LogoBig>
    </div>
  );
};

export default OrangeHeader;
