import { Helmet } from 'react-helmet-async';

const MetaTags = ({
  url,
  title,
  description,
  imageSrc,
  openGraphType = 'website',
  twitterCardType = 'summary_large_image',
  siteName = 'Observatório do Emprego Jovem',
  extras = null
}) => {
  return (
    <Helmet>
      {!!title && <title data-rh="true">{title}</title>}
      {!!description && (
        <meta name="description" content={description} data-rh="true" />
      )}

      <meta name="twitter:card" content={twitterCardType} data-rh="true" />
      {!!title && (
        <meta name="twitter:title" content={imageSrc} data-rh="true" />
      )}
      {!!description && (
        <meta name="twitter:description" content={description} data-rh="true" />
      )}
      {!!imageSrc && (
        <meta name="twitter:image:src" content={imageSrc} data-rh="true" />
      )}

      <meta property="og:type" content={openGraphType} data-rh="true" />
      {!!url && <meta property="og:url" content={url} data-rh="true" />}
      {!!siteName && (
        <meta property="og:site_name" content={siteName} data-rh="true" />
      )}
      {!!title && <meta property="og:title" content={title} data-rh="true" />}
      {!!description && (
        <meta property="og:description" content={description} data-rh="true" />
      )}
      {!!imageSrc && (
        <meta property="og:image" content={imageSrc} data-rh="true" />
      )}
      {extras}
    </Helmet>
  );
};

export default MetaTags;
