import { backendAddress } from '../common/variables';
import { handleResponse, authenticate } from './common';

// import merge from 'lodash/merge';
// import dummies, { getRandomInt } from './DUMMIES';

// export async function getSelf() {
//   await authenticate();
//   return { ok: true, data: dummies.users[0] };
// }

// export async function getUsers() {
//   await authenticate();
//   return { ok: true, data: dummies.users };
// }

// export async function deleteUser(_id) {
//   await authenticate();

//   const target = dummies.users.filter((p) => p._id === _id)[0];
//   if (target) {
//     dummies.users = dummies.users.filter((p) => p._id !== _id);
//     return { ok: true, data: target };
//   }
//   return { ok: false, data: null };
// }

// export async function postUser(user) {
//   await authenticate();
//   user._id = getRandomInt(400, 1000);
//   dummies.users.push(user);
//   return user;
// }

// export async function putUser(user) {
//   await authenticate();

//   const target = dummies.users.filter((p) => p._id === user._id)[0];
//   if (target) {
//     merge(target, user);
//     return { ok: true, data: target };
//   }
//   return { ok: false, data: null };
// }

// export async function getGroups() {
//   await authenticate();
//   return { ok: true, data: dummies.usergroups };
// }

// export async function putGroup(group) {
//   await authenticate();
//   const target = dummies.usergroups.filter((p) => p._id === group._id)[0];
//   if (target) {
//     merge(target, group);
//     return { ok: true, data: target };
//   }
//   return { ok: false, data: null };
// }

// export async function postGroup(group) {
//   await authenticate();
//   group._id = getRandomInt(400, 1000);
//   dummies.usergroups.push(group);
//   return group;
// }

export async function getSelf() {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(backendAddress + 'users/' + userInfo.attributes.sub, {
    method: 'GET',
    //body: data,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo?.signInUserSession.accessToken.jwtToken ?? ''
    }
  });

  return await handleResponse(response);
}

export async function getUsers() {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(backendAddress + 'users', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo?.signInUserSession.accessToken.jwtToken ?? ''
    }
  });
  return await handleResponse(response);
}

export async function deleteUser(_id) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(backendAddress + 'users/' + _id, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo?.signInUserSession.accessToken.jwtToken ?? ''
    }
  });
  return await handleResponse(response);
}

export async function postUser(userData) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(backendAddress + 'users', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo?.signInUserSession.accessToken.jwtToken ?? ''
    },
    body: JSON.stringify(userData)
  });
  return await handleResponse(response);
}

export async function putUser(userData) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(backendAddress + 'users/' + userData.id, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo?.signInUserSession.accessToken.jwtToken ?? ''
    },
    body: JSON.stringify(userData)
  });
  return await handleResponse(response);
}

export async function getGroups() {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(backendAddress + 'usergroups', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
}

export async function putGroup(groupData) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(backendAddress + 'usergroups/' + groupData._id, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: JSON.stringify(groupData)
  });
  return await handleResponse(response);
}

export async function postGroup(groupData) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(backendAddress + 'usergroups', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: JSON.stringify(groupData)
  });
  return await handleResponse(response);
}
