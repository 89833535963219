import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Footer from '../../components/Footer';
import BlueHeader from '../../components/Header/BlueHeader';
import { ReactComponent as DocumentsIcon } from '../../img/icon_document.svg';

import { ReactComponent as EnterpriseIcon } from '../../img/icon_enterprise.svg';
import { ReactComponent as SettingsIcon } from '../../img/icon_settings.svg';
// import { ReactComponent as NifIcon } from '../../img/nifrequest/business.svg';
import './Dashboard.scss';
import HeroNifRequest from './HeroNifRequest';

// OLD BUTTONS!!  const buttonStyle =
//   'flex items-center text-xl text-white bg-finblue px-16 py-1 rounded-xl transform transition hover:scale-105';

// OLD icon style "w-2/12 h-auto rounded-full bg-finorange transform transition

const buttonStyle =
  'flex w-auto h-auto py-8 px-16  min-w-100  flex-col items-center justify-around bg-white  text-3xl font-medium rounded-xl transform transition hover:shadow-md  hover:scale-105 hover:bg-opacity-100  ';

const iconStyle = 'flex w-28 h-28  p-2 rounded-lg  transform transition';

const personalPath = 'app/personal',
  enterprisePath = 'app/enterprise',
  settingsPath = 'app/settings';

// requestNif = 'app/nifrequest';

const Dashboard = () => {
  const { t } = useTranslation();
  return (
    <div className="flex w-full h-auto flex-col relative justify-between items-center bg-gray-100">
      <BlueHeader title="Account" subtitle="In the palm of your hand"></BlueHeader>

      <div className="flex w-full h-auto flex-col  justify-between items-center md:items-center ">
        <HeroNifRequest />

        {/* <div className="flex  w-7/8 md:w-1/4 gap-12 flex-wrap justify-center content-center items-center  border-2   rounded-xl  p-8 my-8">
          <div className="w-full text-center text-3xl font-bold text-gray-300">Requests</div>
          <Link to={requestNif} className={buttonStyle}>
            <span>{t('Get a NIF')}</span>
            <NifIcon className={iconStyle} />
          </Link>
        </div> */}

        <div className="flex w-7/8 lg:w-5/8  gap-12 flex-wrap justify-center  items-center  border-2  rounded-xl  p-8 mb-8 mt-4">
          <div className="flex w-full text-center  text-3xl font-bold text-gray-300">
            My Finpartner
          </div>
          <Link to={personalPath} className={buttonStyle}>
            <span className="pb-6">{t('Personal')}</span>
            <DocumentsIcon className={iconStyle} />
          </Link>
          <Link to={enterprisePath} className={buttonStyle}>
            <span className="pb-6">{t('Enterprise')}</span>
            <EnterpriseIcon className={iconStyle} />
          </Link>
          <Link to={settingsPath} className={buttonStyle}>
            <span className="pb-6">{t('Settings')}</span>
            <SettingsIcon className={iconStyle} />
          </Link>
        </div>
      </div>

      <Footer></Footer>
    </div>
  );
};

export default Dashboard;
