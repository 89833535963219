import Dashboard from '../pagesAdmin/Dashboard';
import DocumentsPersonal from '../pagesAdmin/DocumentsPersonal';
import DocumentsEnterprise from '../pagesAdmin/DocumentsEnterprise';
import Settings from '../pagesAdmin/Settings';
import NifRequest from '../pagesAdmin/NifRequest/NifHome';
import NifRequestFrom from '../pagesAdmin/NifRequest/NifRequestForm';
import NifRequestList from '../pagesAdmin/NifRequest/NifRequestList';
const routes = [
  {
    label: 'Dashboard',
    protected: true,
    noMenu: true,
    path: '/app',
    exact: true,
    component: Dashboard
  },
  {
    label: 'Personal',
    protected: true,
    path: '/app/personal',
    exact: true,
    component: DocumentsPersonal
  },
  {
    label: 'Enterprise',
    protected: true,
    path: '/app/enterprise',
    exact: true,
    component: DocumentsEnterprise
  },
  {
    label: 'Settings',
    protected: true,
    path: '/app/settings',
    exact: false,
    component: Settings
  },
  {
    label: 'Nif Request',
    protected: true,
    path: '/app/nifrequest',
    exact: false,
    component: NifRequest
  },
  {
    label: 'Nif Request Form',
    protected: true,
    path: '/app/nifrequestform',
    exact: false,
    component: NifRequestFrom
  },
  {
    label: 'Nif Applications List',
    protected: true,
    path: '/app/nifapplications',
    exact: false,
    component: NifRequestList
  }
];
export default routes;
