import React from 'react';
import moment from 'moment';
import {
  CalendarIcon,
  DocumentDownloadIcon,
  TagIcon
  // TrashIcon,
  // ExclamationCircleIcon,
  // CheckCircleIcon
} from '@heroicons/react/outline';
// import { toast } from 'react-toastify';

import sort from 'fast-sort';

import { useTranslation } from 'react-i18next';

// import ConfirmDelete from '../../components/ConfirmDelete';
// import { deleteCompanyDocument as beDeleteCompanyDocument } from '../../network/documents';
import { downloadFile as s3Download /*removeFile as s3Remove*/ } from '../../common/s3';

import { ReactComponent as FileIcon } from '../../img/icon_file.svg';

const DocumentGrid = ({ company, documents, setDocuments }) => {
  const { t } = useTranslation();

  // const removeDocument = async (document) => {
  //   const response = await s3Remove(document.id);

  //   let error = false;
  //   console.log(response);
  //   if (response.ok) {
  //     const beResponse = await beDeleteCompanyDocument(company?._id, document.id);
  //     console.log(beResponse);
  //     if (!beResponse?.ok) {
  //       error = beResponse?.data || true;
  //     }
  //   } else {
  //     error = response?.data || true;
  //   }
  //   if (error) {
  //     toast.error(
  //       <ExclamationCircleIcon className="stroke-current stroke-2 w-8 h-8"></ExclamationCircleIcon>
  //     );
  //   } else {
  //     toast.success(
  //       <CheckCircleIcon className="stroke-current stroke-2 w-8 h-8"></CheckCircleIcon>
  //     );
  //     setDocuments(documents.filter((x) => x.id !== document.id));
  //   }
  // };

  return documents?.map((document, index) => {
    const date = `${moment(document.createdAt).format('YYYY/MM/DD HH:ss')}`;
    const tags = sort(document?.tags ?? []).asc();
    //console.log('rendering doc: ', { ...document, date });
    // const allowDelete = document.source === 'client';
    return (
      <div key={document.name + index} className="w-auto md:w-1/3 pb-4 pr-4">
        <div className="w-full h-auto break-all min-h-60 flex flex-col items-start bg-gray-50 p-6 rounded-xl transform transition hover:shadow-md">
          <div className="w-full flex-grow-0 flex items-center">
            <div className="w-2/12 h-auto rounded-full bg-finorange transform transition">
              <FileIcon className="w-full h-auto p-3" />
            </div>
            <h4 className="w-10/12 h-full flex items-center text-xl pl-6">{document.name}</h4>
          </div>

          <div className="w-full mt-6 flex-grow-1 flex flex-col justify-end gap-2">
            <div className="w-full flex items-center px-2 mb-2">
              <CalendarIcon className="h-8 w-8 mr-2"></CalendarIcon>
              {date}
            </div>
            <div className="w-full flex items-center flex-wrap justify-start px-2 mb-2 select-none">
              {tags.map((tag) => (
                <div key={tag} className="flex items-center px-3 py-1 mb-2 text-xs w-auto bg-finblue bg-opacity-25 rounded-xl mr-2">
                  <TagIcon className="h-4 w-4 stroke-2 stroke-current mr-2"></TagIcon>
                  <span>{tag}</span>
                </div>
              ))}
            </div>
            <button
              onClick={() => s3Download(document.id)}
              className="w-auto flex items-center rounded-xl border transition transform hover:scale-105 hover:bg-finblue hover:text-white p-2"
            >
              <DocumentDownloadIcon className="h-8 w-8 mr-2"></DocumentDownloadIcon>
              {t('download')}
            </button>
            {/* <ConfirmDelete
              allowDelete={allowDelete}
              onDelete={() => removeDocument(document)}
              className={`rounded-xl border transition transform p-2 ${
                allowDelete ? 'hover:scale-105 hover:bg-red-600 hover:text-white' : ''
              }`}
              deleteLabel={
                <div className={`flex items-center ${allowDelete ? '' : 'text-gray-300'}`}>
                  <TrashIcon className="h-8 w-8 mr-2"></TrashIcon>
                  {t('delete')}
                </div>
              }
              confirmCaption={<span className="text-red-500 mb-4">{t('action_irreversible')}</span>}
              confirmLabel={t('confirm')}
            ></ConfirmDelete> */}
          </div>
        </div>
      </div>
    );
  });
};

export default DocumentGrid;
