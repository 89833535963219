import React, { Component } from 'react';
import OrangeHeader from '../../components/Header/OrangeHeader';
import AuthLogin from '../../components/AuthLogin';
import LanguageBar from '../../components/LanguageBar';

import './Home.scss';
import { Route, Switch } from 'react-router-dom';
import AuthRecover from '../../components/AuthRecover';
import AuthRegister from '../../components/AuthRegister';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = { posts: [] };
  }

  render() {
    return (
      <div
        id="home"
        className="relative flex flex-col justify-start items-center w-screen h-screen bg-white"
      >
        <OrangeHeader></OrangeHeader>
        <div className="relative -top-8 h-auto w-5/6 sm:w-2/3 md:w-1/3 flex flex-col justify-start align-center">
          <div className="w-full flex flex-col justify-center align-start">
            <h1 className="text-finorange text-5xl font-extrabold pb-6">
              {this.props?.title ?? (
                <>
                  Sign <span className="text-finblue">In</span>
                </>
              )}
            </h1>
            <span className="text-gray-500 text-lg font-normal pb-10">
              Please enter your credentials to proceed
            </span>
          </div>
          <Switch>
            <Route exact path="/">
              <AuthLogin className="w-full pb-12"></AuthLogin>
            </Route>
            <Route exact path="/login">
              <AuthLogin className="w-full pb-12"></AuthLogin>
            </Route>
            <Route path="/recover">
              <AuthRecover />
            </Route>
            <Route path="/register">
              <AuthRegister />
            </Route>
          </Switch>

          <div className="w-full flex justify-between align-start flex-wrap pt-4">
            <a
              href="google.com"
              target="_blank"
              rel="noopener noreferer"
              className="font-bold text-xl text-finblue hover:text-blue-700 pb-4"
            >
              Privacy Policy
            </a>

            <LanguageBar className="items-center pb-4"></LanguageBar>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
