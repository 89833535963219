import React from 'react';

import Ellipsis from '../Ellipsis';

import { isEnter } from '../../common/util';

import './Input.scss';

const affixDefaultWidth = 80;
const affixDefaultScale = 0.3;

const getPaddingRight = (width, scale, extraLeft = 0) => {
  const realWidth = width ?? affixDefaultWidth;
  const realScale = scale ?? affixDefaultScale;

  const newWidth = realWidth * realScale;
  return -extraLeft + (realWidth - newWidth) / 2;
};

const getRight = (width, scale, extraLeft = 0) => {
  const realWidth = width ?? affixDefaultWidth;
  const realScale = scale ?? affixDefaultScale;

  const newWidth = realWidth * realScale;
  return -extraLeft + 2 + newWidth + (realWidth - newWidth) / 2;
};

const onChangeEvt = (onChange) => (evt) => {
  return onChange(evt.target.value);
};

const Input = ({
  initialValue,
  onSave = () => {},
  onChange,
  className = '',
  containerClassName = '',
  outerClassName = 'w-full h-full',
  label = '',
  type = 'text',
  value,
  AffixComponent = Ellipsis,
  affixComponentProps = {},
  disableAffix = true,
  ...inputProps
}) => {
  const [saving, setSaving] = React.useState(false);

  const inputRef = React.createRef();

  const onChangeEvent = onChangeEvt(onChange);

  const localOnSave = async (value) => {
    setSaving(true);
    await onSave(value);
    await new Promise((r) => setTimeout(r, 450));
    setSaving(false);
  };

  return (
    <div className={`input relative flex flex-col ${outerClassName}`}>
      {!!label && <span className="text-sm">{label}</span>}
      <div className={`relative flex h-auto ${containerClassName}`}>
        <input
          ref={inputRef}
          className={`input-inner ${className} rounded-md focus:outline-none ${
            !className.includes('bg-') ? 'bg-white' : ''
          } bg-transparent ${!className.includes('px-') ? 'px-4' : ''} ${
            !className.includes('py-') ? 'py-2' : ''
          } ${!className.includes('border') ? 'border border-gray-300' : ''}`}
          style={{
            paddingRight: getPaddingRight(
              affixDefaultWidth,
              affixComponentProps?.scale,
              inputRef.current?.clientWidth ?? 0
            )
          }}
          value={value ?? undefined}
          onChange={onChange ? onChangeEvent : () => {}}
          type={type}
          defaultValue={initialValue}
          onKeyDown={(e) => {
            if (isEnter(e)) {
              const value = e.target.value;
              if (value !== initialValue) {
                return localOnSave(value);
              }
            }
          }}
          onBlur={(e) => {
            const value = e.target.value;
            if (value !== initialValue) {
              return localOnSave(value);
            }
          }}
          {...inputProps}
        ></input>

        {!disableAffix && (
          <div
            className="absolute right-0 h-full flex items-center justify-end"
            style={{
              right: -getRight(
                affixDefaultWidth,
                affixComponentProps?.scale,
                inputRef.current?.clientWidth ?? 0
              )
            }}
          >
            <AffixComponent
              backgroundColor="#5ea2ef"
              scale={affixDefaultScale}
              {...affixComponentProps}
              opacity={saving ? 1 : 0}
              zIndex={saving ? 1000 : -1000}
            ></AffixComponent>
          </div>
        )}
      </div>
    </div>
  );
};

export default Input;
