import React from 'react';
import { useTranslation } from 'react-i18next';

import Ring from '../../components/Ring';
import ControlBar from './ControlBar';
import DocumentGrid from './DocumentGrid';

const CompanyDocumentGrid = ({ company, className = '' }) => {
  const [documents, setDocuments] = React.useState(company?.data?.documents);
  const { t } = useTranslation();

  React.useEffect(() => {
    setDocuments(company?.data?.documents);
  }, [company, company?.data, company?.data?.documents]);

  return (
    <div className={`w-full flex flex-col ${className}`}>
      <div className="w-full flex flex-wrap content-start gap-4 justify-between items-center pb-4">
        <h1 className="text-3xl text-finblue">{company?.name}</h1>

        <ControlBar
          company={company}
          documents={documents}
          setDocuments={setDocuments}
          resetDocuments={() => setDocuments(company?.data?.documents)}
        ></ControlBar>
      </div>
      <div className="w-full h-0 mb-4 border-b-2 border-gray-200"></div>
      {documents?.length > 0 ? (
        <div className="w-full flex flex-wrap items-center">
          <DocumentGrid
            company={company}
            documents={documents}
            setDocuments={setDocuments}
          ></DocumentGrid>
        </div>
      ) : documents?.length === 0 ? (
        <div className="w-full flex flex-wrap items-center">
          <div className="text-gray-500">{t('no_documents')}</div>
        </div>
      ) : (
        <Ring></Ring>
      )}
    </div>
  );
};

export default CompanyDocumentGrid;
