import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as LogoSmall } from '../../img/logo_small.svg';
import { ReactComponent as LogoSmallOrange } from '../../img/logo_small_orange.svg';

import './Header.scss';
import './blob.scss';

const BlueHeader = ({ title, subtitle, className = '' }) => {
  return (
    <div
      id="header"
      className={`relative w-full h-44 bg-finblue flex justify-start items-center z-0 ${className} max-w-screen  `}
    >
      <div className="h-full w-1/12 flex justify-center items-center">
        <Link to="/app">
          <LogoSmallOrange className="px-2 mx-6" />
        </Link>
      </div>
      <div className="h-full w-10/12 md:w-8/12 flex flex-col justify-center pl-4 md:pl-0 pointer-events-none">
        <h1 className="text-4xl md:text-6xl font-light uppercase text-finorange">{title}</h1>
        <h2 className="text-xl md:text-3xl font-light uppercase text-white">{subtitle}</h2>
      </div>

      <div className="fixed h-40 w-40  top-0 right-0 flex justify-end items-start transform origin-top-right transition  cursor-pointer ">
        {/* <Link
          className="w-8 h-8 relative -left-16 bg-gray-100 bg-opacity-5 cursor-default"
          to={'app/nifrequest'}
        ></Link> */}
        <Blob className={'z-50'} />
      </div>
    </div>
  );
};

const Blob = () => {
  return (
    <div className="blob -my-12  -mx-20 ">
      <Link to="/app">
        <LogoSmall
          id="logo-small"
          className="absolute w-auto h-1/4 md:h-1/3 z-20 top-20 right-24"
        ></LogoSmall>
      </Link>
    </div>
  );
};
export default BlueHeader;
