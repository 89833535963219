import React from 'react';

export default function PaymentInfo() {
  return (
    <div className="">
      <div className="text-xl font-extrabold text-finblue ">Payment Info:</div>
      <div className="text-xl font-bold text-finblue ">IBAN: PT50 0010 0000 36632940001 28</div>
      <div className="text-xl font-bold text-finblue "> SWIFT/BIC: BBPIPTPL</div>
      <div className="text-xl font-bold text-finblue "> Finpartner</div>
      <div className="text-xl font-bold text-finblue ">
        Address: R. Castilho 39 14º, 1250-068 Lisboa
      </div>
    </div>
  );
}
