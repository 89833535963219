import React, { useEffect, useState, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import isEmail from 'validator/lib/isEmail';

import AppContext from '../../context/AppContextBase';
import Ring from '../Ring';

import './Login.scss';

const adminRoute = '/app';
const recoverRoute = '/recover';

const Login = ({ className = '' }) => {
  const [loading, setLoading] = useState(false);
  const appContext = useContext(AppContext);
  const history = useHistory();

  const fromRoute = history?.location?.state?.from;

  // Did mount
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const user = await appContext.currentUser();

      console.log('In Login, got user from context ' + user?.name + ' and fromRoute ' + fromRoute);
      if (user) {
        setLoading(false);
        if (fromRoute) {
          console.log('Login: redirecting to original route: ', fromRoute);
          history.push(fromRoute);
        } else {
          console.log('Login: redirecting to default admin');
          history.push(adminRoute);
        }
        return;
      }
      setLoading(false);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (values, { setSubmitting }) => {
    const errorOut = async (e) => {
      await appContext.logOut();

      if (e.message.includes('User is not confirmed.')) {
        toast.error('Por favor, confirme o seu email utilizando o e-mail que lhe foi enviado.');
      } else if (e.message.includes(' Incorrect username or password.')) {
        toast.error('Email ou password incorrectos.');
      } else if (e.message.includes('User does not exist.')) {
        toast.error('Email ou password incorrectos.');
      } else {
        toast.error(
          <div>
            <div>Unexpected Error</div>
            {e.message ? <pre>{e.message}</pre> : null}
          </div>
        );
      }
      console.error(e);
      return;
    };

    setSubmitting(true);
    let awsUser;
    try {
      /* login in AWS */
      awsUser = await appContext.logInAws(values.username, values.password);
      if (!awsUser) {
        setSubmitting(false);
        toast.error('Erro a autenticar: utilizador não encontrado.');
        return;
      }
    } catch (e) {
      errorOut(e);
      setSubmitting(false);
      return;
    }
    /* Check first time challenge */
    if (awsUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
      history.push(recoverRoute);
      setSubmitting(false);
    } else {
      /* Otherwise, authenticate in BE */
      try {
        await appContext.loginBackend(awsUser);
        setSubmitting(false);
        if (fromRoute) {
          //console.log('Login: redirecting to from: ', fromRoute);
          history.push(fromRoute);
        } else {
          //console.log('Login: redirecting to default admin');
          history.push(adminRoute);
        }
        return;
      } catch (err) {
        errorOut(err);
        return;
      }
    }
  };

  const validate = (values) => {
    const errors = {};

    if (!values.username || !isEmail(values.username)) {
      errors.username = 'Required';
    }

    if (!values.password) {
      errors.password = 'Required';
    }

    return errors;
  };

  return (
    <div id="login" className={`w-full flex justify-start items-center ${className}`}>
      <div className="w-full h-auto flex justify-center items-center">
        <Formik
          className="w-full"
          initialValues={{ username: '', password: '' }}
          validate={validate}
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => (
            <Form className="login-form w-full h-full flex flex-col justify-start items-center">
              {!!loading && (
                <Ring
                  className="max-w-72 w-auto flex justify-center items-end"
                  size={40}
                  scale={0.5}
                ></Ring>
              )}
              <div className="w-full flex flex-col">
                <div className="w-full mb-4 flex flex-col justify-start items-start">
                  <Field
                    type="email"
                    name="username"
                    placeholder="Enter Email"
                    className="w-full rounded-lg py-4 px-6 mb-4 text-gray-800 bg-gray-200 focus:bg-gray-100 leading-tight appearance-none focus:outline-none"
                  />
                  <ErrorMessage
                    className="text-finorange self-end"
                    name="username"
                    component="div"
                  />
                </div>

                <div className="flex flex-col justify-start items-start">
                  <Field
                    type="password"
                    name="password"
                    placeholder="Enter Password"
                    className="w-full rounded-lg py-4 px-6 mb-4 text-gray-800 bg-gray-200 focus:bg-gray-100 leading-tight appearance-none focus:outline-none"
                  />
                  <ErrorMessage
                    className="text-finorange self-end"
                    name="password"
                    component="div"
                  />
                </div>
                <div className="flex items-center justify-between pt-3 pb-6">
                  <button
                    className="inline-block align-baseline font-bold text-xl text-finblue border border-finblue px-3 py-1 mr-3 rounded-md hover:text-white hover:bg-finblue transition"
                    disabled={isSubmitting || loading}
                  >
                    <Link to="/recover">Forgot Password?</Link>
                  </button>
                  <button
                    className="inline-block align-baseline font-bold text-xl text-finblue border border-finblue px-3 py-1 rounded-md hover:text-white hover:bg-finblue transition"
                    disabled={isSubmitting || loading}
                  >
                    <Link to="/register">Register New Account</Link>
                  </button>
                </div>
                <div className="flex items-center justify-center">
                  <button
                    className="w-full bg-finblue hover:bg-blue-800 text-white text-2xl font-thin tracking-wider uppercase py-4 px-6 rounded-lg focus:outline-none focus:shadow-outline"
                    type="submit"
                    disabled={isSubmitting || loading}
                  >
                    Sign In
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Login;
