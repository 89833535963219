import { backendAddress } from '../common/variables';
// eslint-disable-next-line no-unused-vars
import { handleResponse, authenticate, errors } from './common';

const bucketName = 'finpartner-userfiles-mobilehub-920305426';
const folderName = '/public';
const clientSource = 'client';

export const getDocuments = async (_id) => {
  const userInfo = await authenticate();

  const response = await fetch(backendAddress + 'documents/' + _id, {
    method: 'GET',
    //body: data,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo?.signInUserSession.accessToken.jwtToken ?? ''
    }
  });

  return await handleResponse(response);
};

export async function postDocument(fileName, s3Key) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }
  const body = {
    id: s3Key,
    source: clientSource,
    name: fileName,
    url: 'https://' + bucketName + '.s3.amazonaws.com' + folderName + '/' + s3Key,
    description: 'test description'
  };

  const response = await fetch(backendAddress + 'documents/', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: JSON.stringify(body)
  });
  return await handleResponse(response);
}

export async function deleteDocument(userId, documentId) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(backendAddress + `documents/user/${userId}/${documentId}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });

  return await handleResponse(response);
}

export async function deleteCompanyDocument(userGroupId, documentId) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(backendAddress + `groups/${userGroupId}/documents/${documentId}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });

  return await handleResponse(response);
}

export async function postCompanyDocument(userGroupId, fileName, s3Key) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const body = {
    id: s3Key,
    source: clientSource,
    name: fileName,
    url: 'https://' + bucketName + '.s3.amazonaws.com' + folderName + '/' + s3Key,
    description: ''
  };

  const response = await fetch(backendAddress + `groups/${userGroupId}/documents`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: JSON.stringify(body)
  });

  return await handleResponse(response);
}
