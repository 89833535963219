import autobind from 'auto-bind/react';
import React from 'react';
import { getSelf } from '../network/users';
import BusinessContext from './BusinessContextBase';

class BusinessContextProvider extends React.Component {
  constructor(props) {
    super(props);
    autobind(this);

    this.state = {
      self: null
    };
  }

  asyncInit;

  async componentDidMount() {
    console.log('Context: fetching data');
    this.asyncInit = await this.fetchData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.self !== this.state.self || prevState.myUsergroups !== this.state.myUsergroups) {
      if (this.state.myUsergroups && this.state.self) {
        const userIsAdmin = this.state.myUsergroups.some((ug) => ug.isAdmin);
        this.setState({ userIsAdmin });
      }
    }
  }

  async fetchData() {
    await this.fetchSelf();
  }

  async fetchSelf() {
    const response = await getSelf();
    // console.log(response?.data);
    const self = Array.isArray(response?.data) ? response?.data[0] : response?.data;
    if (response?.ok && self) {
      this.setState({ self });
    }
  }

  render() {
    // console.log('this.state.userData', this.state.userData);
    return (
      <BusinessContext.Provider
        value={{
          state: this.state,
          fetchData: this.fetchData,
          fetchSelf: this.fetchSelf,
          self: this.state.self
        }}
      >
        {this.props.children}
      </BusinessContext.Provider>
    );
  }
}

const BusinessContextConsumer = BusinessContext.Consumer;

export { BusinessContextProvider, BusinessContextConsumer };
