import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowSmLeftIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';

import BusinessContext from '../../context/BusinessContextBase';
import BlueHeader from '../../components/Header/BlueHeader';
import Footer from '../../components/Footer';
import CodePreview from '../../components/CodePreview';
import Ring from '../../components/Ring';
import { getGroup } from '../../network/groups';

import './DocumentsEnterprise.scss';
import CompanyDocumentGrid from './CompanyGrid';

const DocumentList = () => {
  const businessContext = React.useContext(BusinessContext);
  const [companies, setCompanies] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  const { t } = useTranslation();

  React.useEffect(() => {
    const getUsergroups = async () => {
      setLoading(true);
      const usergroupIds = businessContext.self?.usergroups;
      let usergroups = [];
      if (usergroupIds) {
        for (const oid of usergroupIds) {
          const ugResponse = await getGroup(oid);

          console.log(ugResponse);
          if (ugResponse?.ok) {
            const usergroup = ugResponse.data;
            console.log(usergroup);
            usergroups.push(usergroup);
          }
        }
        setCompanies(usergroups);
      } else {
        setCompanies([]);
      }
      setLoading(false);
      console.log(usergroupIds);
    };

    getUsergroups();
  }, [businessContext.self]); // eslint-disable-line react-hooks/exhaustive-deps

  const goBackButton = (
    <Link
      to={'/app'}
      className="flex justify-center items-center text-xl border border-finorange text-finorange hover:bg-finorange hover:text-white px-4 py-2 rounded-xl transform transition hover:scale-105"
    >
      <ArrowSmLeftIcon className="text-cyan-600 stroke-2 w-8 h-8 mr-0 md:mr-4"></ArrowSmLeftIcon>
      <span className="hidden md:inline">{t('back')}</span>
    </Link>
  );

  const noDocuments = (
    <div className="w-full h-full flex-grow flex flex-col">
      <h1 className="text-3xl text-gray-700 pb-12">{t('no_documents')}</h1>
      <div className="text-xl text-gray-600">{t('no_companies')}</div>
    </div>
  );

  return (
    <div className="w-full h-full flex-grow flex flex-col justify-between items-center">
      <BlueHeader title="Account" subtitle="In the palm of your hand"></BlueHeader>
      <div id="enterprise" className="w-10/12 h-auto flex-grow flex flex-col py-12">
        <div className="w-full h-auto flex justify-between items-center pb-6">
          <h1 className="text-4xl text-finblue">{t('enterprise_area')}</h1>
          {goBackButton}
        </div>

        {loading ? (
          <Ring></Ring>
        ) : companies?.length > 0 ? (
          <div className="w-full h-auto flex-grow flex flex-col">
            {companies.map((company) => {
              if (!company?._id) {
                return null;
              }
              return (
                <CompanyDocumentGrid
                  className="rounded-xl w-full bg-white p-8 mb-8 border border-gray-200"
                  company={company}
                ></CompanyDocumentGrid>
              );
            })}
          </div>
        ) : (
          noDocuments
        )}

        <CodePreview className="w-10/12 pb-4" post={companies} label="JSON"></CodePreview>
      </div>

      <Footer></Footer>
    </div>
  );
};

export default DocumentList;
