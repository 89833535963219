import React from 'react';
import { ExclamationIcon } from '@heroicons/react/outline';

import Popover from '../Popover';
import Ellipsis from '../Ellipsis';

const ConfirmDelete = ({
  onDelete,
  className = '',
  allowDelete = true,
  deleteLabel,
  confirmLabel = 'Confirm removal',
  confirmCaption = null,
  disabled = false
}) => {
  const [isOpen, setOpen] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);

  const confirmContent = (
    <div className="w-full bg-white rounded-md shadow-md p-8">
      <div className="w-full flex flex-col items-center text-white">
        {isLoading ? (
          <Ellipsis scale={0.3} backgroundColor={'rgb(239, 68, 68)'}></Ellipsis>
        ) : (
          <ExclamationIcon className="text-red-500 w-12 h-12 mb-4 stroke-current"></ExclamationIcon>
        )}
        {confirmCaption}
        <button
          disabled={isLoading || disabled}
          onClick={async () => {
            setLoading(true);
            await onDelete();
            setLoading(false);
            setOpen(false);
          }}
          className="h-full w-full flex justify-center items-center mb-4 bg-red-400 hover:bg-red-600 hover:shadow-md py-2 px-4 rounded focus:outline-none"
        >
          <span className="whitespace-nowrap">{confirmLabel}</span>
        </button>
        {/* <button
          disabled={isLoading}
          onClick={() => {
            console.log('cancel');
            setOpen(false);
          }}
          className="h-full w-full flex justify-center items-center bg-gray-300 hover:bg-gray-400 hover:shadow-md py-2 px-4 rounded focus:outline-none"
        >
          <span className="pl-4 whitespace-nowrap">{cancelLabel}</span>
        </button> */}
      </div>
    </div>
  );
  return (
    <div className={`confirm-delete rounded-md flex pr-0 ${className}`}>
      <div
        disabled={isLoading || disabled}
        onClick={() => {
          if (!isLoading && !disabled) {
            setOpen(true);
          }
        }}
        className={`h-full w-full cursor-pointer transition flex flex-col justify-center align-center`}
      >
        {allowDelete ? (
          <Popover
            onSetOpen={setOpen}
            isOpen={isOpen}
            content={confirmContent}
            containerWidth="auto"
          >
            {deleteLabel ? (
              deleteLabel
            ) : (
              <button
                disabled={isLoading}
                className="flex justify-center items-center text-white bg-red-600 hover:bg-red-700 hover:shadow-md py-2 px-4 rounded focus:outline-none"
              >
                <span className="whitespace-nowrap">Delete</span>
              </button>
            )}
          </Popover>
        ) : deleteLabel ? (
          deleteLabel
        ) : (
          <button
            disabled={true}
            className="flex justify-center items-center text-gray-200 py-2 px-4 rounded focus:outline-none"
          >
            <span className="whitespace-nowrap">Delete</span>
          </button>
        )}
      </div>
    </div>
  );
};

export default ConfirmDelete;
