import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import BusinessContext from '../../../context/BusinessContextBase';
import AppContext from '../../../context/AppContextBase';
import CodePreview from '../../../components/CodePreview';
import LanguageBar from '../../../components/LanguageBar';

import { ReactComponent as LogoutIcon } from '../../../img/icon_logout.svg';
import { ReactComponent as UserIcon } from '../../../img/icon_user.svg';

import './SettingsAccount.scss';

const SettingsAccount = () => {
  const businessContext = React.useContext(BusinessContext);
  const appContext = React.useContext(AppContext);
  const { t } = useTranslation();

  //const [formOpen, setformOpen] = React.useState(false);

  const self = businessContext.self;

  return (
    <div className="settings-account py-12 relative w-full h-full flex-grow flex flex-col justify-start items-start">
      <div className="w-full flex justify-between items-center">
        <h1 className="text-5xl pb-8 text-finblue">{t('Minha conta')}</h1>
        <Link
          to={'/app'}
          className="flex items-center mr-4 text-xl border border-finblue text-finblue px-12 py-1 rounded-xl transform transition hover:scale-105"
        >
          {t('back')}
        </Link>
      </div>

      <div className="w-full h-full flex flex-col md:flex-row items-start">
        <div className="flex h-auto w-2/12 rounded-full bg-white">
          <UserIcon className="w-full h-auto" />
        </div>
        <div className="relative h-full w-full md:w-10/12 ml-0 md:ml-8 flex-grow flex flex-col justify-between">
          <div className="w-full flex flex-col">
            <div className="flex items-center text-2xl text-finorange pb-2">
              <span className="min-w-32 pr-1">{t('name')}:</span>
              {self?.name}
            </div>
            <div className="flex items-center text-2xl text-finorange pb-12">
              <span className="min-w-32 pr-1">{t('email')}:</span> {self?.email}
            </div>
            <LanguageBar className="items-center pb-12"></LanguageBar>
            <div className="w-full flex items-center pb-8">
              <button
                onClick={appContext.logOut}
                className="flex items-center mr-4 text-xl text-white bg-finblue hover:bg-red-700 px-12 py-1 rounded-xl transform transition hover:scale-105"
              >
                <LogoutIcon className="h-10 py-2 mr-6 w-auto flex" />
                {t('signout')}
              </button>

              {/* <Link
                to={`/renew`}
                className="flex items-center mr-4 text-xl text-white bg-finblue px-12 py-1 rounded-xl transform transition hover:scale-105"
              >
                <LockIcon className="h-10 py-2 mr-6 w-auto flex" />
                {t('change_password')}
              </Link> */}
            </div>
          </div>
          <div className="w-full flex flex-col">
            <div className="w-full flex items-center pb-8">
              <a
                className="flex items-center mr-4 text-lg text-white bg-finblue px-12 py-2 rounded-xl transform transition hover:scale-105"
                href="https://www.finpartner.pt/terms-and-conditions/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('terms_conditions')}
              </a>
              <a
                className="flex items-center mr-4 text-lg text-white bg-finblue px-12 py-2 rounded-xl transform transition hover:scale-105"
                href="https://www.finpartner.pt/privacy-policy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('privacy_policy')}
              </a>

              <a
                className="flex items-center mr-4 text-lg text-white bg-finblue px-12 py-2 rounded-xl transform transition hover:scale-105"
                href="https://www.finpartner.pt/contacts/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('contacts')}
              </a>
            </div>
            <CodePreview label="self" post={self}></CodePreview>
          </div>
        </div>
      </div>

      {/* <div className="w-full h-auto flex flex-col rounded mb-4">
        <div className="h-12 w-auto">
          <button
            className={`h-full flex items-center ${
              !formOpen ? 'bg-cyan-600 hover:bg-cyan-500' : 'bg-red-400 hover:bg-red-500'
            } py-2 px-4 rounded focus:outline-none`}
            onClick={() => {
              setformOpen(!formOpen);
            }}
          >
            {formOpen ? (
              <>
                <XIcon className="w-6 h-6"></XIcon>
                <span className="pl-4 whitespace-nowrap">Cancel</span>
              </>
            ) : (
              <>
                <UserAddIcon className="w-8 h-8"></UserAddIcon>
                <span className="pl-4 whitespace-nowrap">Create User</span>
              </>
            )}
          </button>
        </div>
        {formOpen && <FormCreateUser validate={() => {}} onSubmit={() => {}}></FormCreateUser>}
      </div> */}
    </div>
  );
};

export default SettingsAccount;

// eslint-disable-next-line no-unused-vars
const errorOut = async (awsError) => {
  if (awsError.message.includes('User is not confirmed.')) {
    toast.error('Please confirm your account with the e-mail that was sent to you.');
  } else if (awsError.message.includes(' Incorrect username or password.')) {
    toast.error('Incorrect username or password.');
  } else if (awsError.message.includes('User does not exist.')) {
    toast.error('Incorrect username or password.');
  } else {
    toast.error(
      <div>
        <div>Unexpected error. Please try again</div>
        {awsError.message ? <pre>{awsError.message}</pre> : null}
      </div>
    );
  }
  console.error(awsError);
  return;
};
