import React from 'react';
import { XIcon, UploadIcon, RefreshIcon, ArrowSmLeftIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import BusinessContext from '../../context/BusinessContextBase';
import Input from '../../components/Input';
import TagSelector from '../../components/TagSelector';
import { removeFile as s3Remove, uploadFile as s3Upload } from '../../common/s3';
import { matchSearch } from '../../common/util';

import { postCompanyDocument as bePostCompanyDocument } from '../../network/documents';
import moment from 'moment';
import { Link } from 'react-router-dom';

const ControlBar = ({ company, documents, setDocuments, resetDocuments }) => {
  const [refreshing, setRefreshing] = React.useState(false);
  const [uploading, setUploading] = React.useState(false);
  const [search, setSearch] = React.useState('');
  const [tagSearch, setTagSearch] = React.useState([]);
  const businessContext = React.useContext(BusinessContext);
  const { t } = useTranslation();

  React.useEffect(() => {
    resetDocuments();
    if (search) {
      const newDocuments = documents?.filter((x) => matchSearch(search, x)) ?? [];
      setDocuments(newDocuments);
    }
  }, [search]); // eslint-disable-line react-hooks/exhaustive-deps

  const searchByTags = (tagValueArray) => {
    setTagSearch(tagValueArray);
    let finalDocuments;
    if (!tagValueArray?.length) {
      resetDocuments();
      return;
    } else {
      finalDocuments = documents?.filter((doc) => {
        let hasAllTags = true;
        for (const searchTag of tagValueArray) {
          if (!doc?.tags?.some((docTag) => searchTag === docTag)) {
            hasAllTags = false;
            break;
          }
        }
        return hasAllTags;
      });
    }

    setDocuments(finalDocuments);
  };

  const searchSection = (
    <div className="order-1 md:order-5 w-full sm:w-auto min-w-60 sm:min-w-96 flex items-center">
      <Input
        placeholder={t('search')}
        label={''}
        className="bg-white border border-gray-300 rounded-xl flex-grow-1 placeholder-current text-lg uppercase mr-2"
        containerClassName="h-full flex items-center"
        outerClassName="flex-grow"
        onChange={(value) => {
          setSearch(value);
        }}
        value={search}
      ></Input>
      <div
        className={`flex items-center text-gray-300 cursor-pointer hover:text-gray-700 transition`}
        onClick={() => {
          setSearch('');
        }}
      >
        <XIcon className={'w-12 h-12 mr-4 stroke-current'}></XIcon>
      </div>
    </div>
  );

  const createButton = (
    <label
      className={`order-2  mr-4 cursor-pointer text-lg border ${
        uploading
          ? 'border-gray-300 text-gray-700 animate-pulse'
          : 'border-finorange text-finorange hover:bg-finorange hover:text-white'
      } px-4 py-2 rounded-xl transform transition hover:scale-105 w-auto h-full flex justify-center items-center`}
    >
      {uploading ? (
        <RefreshIcon
          className={`text-cyan-600 stroke-2 w-8 h-8 mr-0 md:mr-4 animate-reverse-spin`}
        ></RefreshIcon>
      ) : (
        <UploadIcon className={`w-8 h-8 mr-0 md:mr-4 rounded-md`}></UploadIcon>
      )}
      <span className="hidden md:inline">{t('add_new')}</span>

      <input
        type="file"
        className="hidden"
        onChange={async (e) => {
          if (uploading) {
            console.log('Still uploading, returning');
            return;
          }
          setUploading(true);
          const files = e.target.files;
          if (files?.length > 1) {
            console.error('Multiple files uploaded, using first file');
          }
          const file = files[0];
          //console.log(file);
          const response = await s3Upload(file);
          try {
            if (response.ok) {
              //console.log(response.data);
              const beResponse = await bePostCompanyDocument(
                company?._id,
                file.name,
                response.data?.key
              );
              if (!beResponse.ok || !beResponse.data) {
                throw new Error('Error uploading to s3: request failed or no data received');
              } else {
                const newDocuments = documents ? [...documents] : [];
                newDocuments.push({ name: file.name, id: response.data?.key, createdAt: moment() });
                //console.error(beResponse.data);
                setDocuments(newDocuments);
              }
            } else {
              throw new Error('Error uploading to backend: request failed');
            }
          } catch (error) {
            console.error('Removing s3 file due to error: ', error);
            console.error(await s3Remove(response.data?.key));
            toast.error('Erro a carregar ficheiro');
          }
          setUploading(false);
        }}
      />
    </label>
  );

  const refreshButton = (
    <button
      onClick={async () => {
        setRefreshing(true);
        await new Promise((r) => setTimeout(r, 300));
        await businessContext.fetchSelf();
        setRefreshing(false);
      }}
      className={`order-3 flex justify-center items-center mr-4 text-lg border border-finorange text-finorange ${
        refreshing ? '' : 'hover:bg-finorange hover:text-white'
      } px-4 py-2 rounded-xl transform transition hover:scale-105`}
    >
      <RefreshIcon
        className={`text-cyan-600 stroke-2 w-8 h-8 mr-0 md:mr-4 ${
          refreshing ? 'animate-reverse-spin' : ''
        }`}
      ></RefreshIcon>
      <span className="hidden md:inline">{t('refresh')}</span>
    </button>
  );

  const goBackButton = (
    <Link
      to={'/app'}
      className="order-5 flex justify-center items-center text-xl border border-finorange text-finorange hover:bg-finorange hover:text-white px-4 py-2 rounded-xl transform transition hover:scale-105"
    >
      <ArrowSmLeftIcon className="text-cyan-600 stroke-2 w-8 h-8 mr-0 md:mr-4"></ArrowSmLeftIcon>
      <span className="hidden md:inline">{t('back')}</span>
    </Link>
  );

  return (
    <div className="flex flex-wrap content-start gap-4 justify-start items-center">
      {createButton}
      {refreshButton}
      <TagSelector
        tagValueArray={tagSearch}
        className="w-96 order-4"
        onChange={searchByTags}
      ></TagSelector>
      {searchSection}
      {goBackButton}
    </div>
  );
};

export default ControlBar;
