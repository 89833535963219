import React from 'react';
import { Collapse } from 'react-collapse';
import { XIcon, ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/outline';

const CodePreview = ({ post, label = 'Post JSON', className = '' }) => {
  const [isCollapseOpen, setIsCollapseOpen] = React.useState(false);
  const [hidden, setHidden] = React.useState(false);
  if (process.env.NODE_ENV !== 'development') {
    return null;
  }
  if (hidden) {
    return null;
  }
  return (
    <div className={'print:hidden h-auto w-full flex flex-col overflow-x-hidden ' + className}>
      <div
        onClick={() => {
          setIsCollapseOpen(!isCollapseOpen);
        }}
        className={`relative w-auto cursor-pointer h-12 flex justify-between items-center break-normal`}
      >
        <div className="flex-grow-1 flex items-center h-full hover:bg-gray-100 transition">
          {isCollapseOpen ? (
            <ChevronDownIcon className="h-1/2"></ChevronDownIcon>
          ) : (
            <ChevronRightIcon className="h-1/2"></ChevronRightIcon>
          )}
          <span className="px-4">{label}</span>
        </div>
        <div className="flex justify-end items-center h-full hover:bg-gray-100 transition">
          <XIcon
            className={'text-gray-400 stroke-current h-1/2'}
            onClick={() => setHidden(true)}
          ></XIcon>
        </div>
      </div>
      <Collapse isOpened={isCollapseOpen} className={'w-auto h-auto break-normal'}>
        <pre className="w-full h-auto break-normal">{JSON.stringify(post, null, 2)}</pre>
      </Collapse>
    </div>
  );
};

export default CodePreview;
