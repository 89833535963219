const LanguageButton = ({
  imageSrc,
  lang,
  onClick = () => {},
  className = '',
  imageClassName = ''
}) => {
  return (
    <div
      onClick={onClick}
      className={`bg-transparent cursor-pointer transition transform hover:scale-105 ${className}`}
    >
      <img
        src={imageSrc}
        alt={lang}
        className={`rounded-full w-full h-full˙ ${imageClassName}`}
      ></img>
    </div>
  );
};

export default LanguageButton;
