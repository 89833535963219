const awsmobile = {
  aws_app_analytics: 'disabled',
  aws_cognito_identity_pool_id: 'us-east-1:4b4a29d1-8b7b-4860-99bd-37a5ebe9a223',
  aws_cognito_region: 'us-east-1',
  aws_project_region: 'us-east-1',
  //  aws_resource_name_prefix: '',
  aws_sign_in_enabled: 'enable',
  aws_user_files: 'enable',
  aws_user_files_s3_bucket: 'finpartner-userfiles-mobilehub-920305426',
  aws_user_files_s3_bucket_region: 'us-east-1',
  aws_user_pools: 'enable',
  aws_user_pools_id: 'us-east-1_ilIEIzkCq',
  aws_user_pools_web_client_id: 'nkg87q9rev0hpcu8ktfcbvj9e'
};

export default awsmobile;
