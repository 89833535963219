import React from 'react';
import autobind from 'auto-bind/react';
import i18next from 'i18next';
import { toast } from 'react-toastify';

import ls from 'local-storage';

import LanguageContext from './LanguageContextBase';

const languages = {
  en: { shortCode: 'en', standardCode: 'en-US', dictionaryKey: 'en_US' },
  pt: { shortCode: 'pt', standardCode: 'pt-PT', dictionaryKey: 'pt_PT' },
  fr: { shortCode: 'fr', standardCode: 'fr-FR', dictionaryKey: 'fr_FR' },
  es: { shortCode: 'es', standardCode: 'es-ES', dictionaryKey: 'sp_SP' },
  zh: { shortCode: 'zh', standardCode: 'zh-CN', dictionaryKey: 'cn_CN' }
};

const fallbackLanguage = languages.en.shortCode;

export default class LanguageContextProvider extends React.Component {
  constructor(props) {
    super(props);
    autobind(this);

    let userLanguage = ls.get('userLanguage');
    if (!userLanguage || !languages[userLanguage]) {
      ls.set('userLanguage', fallbackLanguage);
      userLanguage = fallbackLanguage;
      console.log('Set userLanguage in local storage: ' + userLanguage);
    } else {
      console.log('Got userLanguage from local storage: ' + userLanguage);
    }

    this.state = {
      language: userLanguage
    };
  }

  asyncInit;

  async setLanguage(shortCode) {
    if (!languages[shortCode]) {
      console.error('LanguageContext.setLanguage - invalid language: ' + shortCode);
      return;
    }

    const standardCodeLanguageCode = languages[shortCode].standardCode;

    try {
      await i18next.changeLanguage(standardCodeLanguageCode);
      this.setState({ language: shortCode });
    } catch (error) {
      console.error(error);
      toast.error('Error changing language');
    }
  }

  render() {
    // console.log('this.state.userData', this.state.userData);
    return (
      <LanguageContext.Provider
        value={{
          setLanguage: this.setLanguage,
          language: this.state.language
        }}
      >
        {this.props.children}
      </LanguageContext.Provider>
    );
  }
}

const LanguageContextConsumer = LanguageContext.Consumer;

export { LanguageContextProvider, LanguageContextConsumer, languages, fallbackLanguage };
