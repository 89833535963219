import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import AppContext from '../../context/AppContextBase';
import './Register.scss';
import Ring from '../Ring';
import { postUser } from '../../network/users';

const loginRoute = '/login';

const errorOut = async (e) => {
  if (e.message.includes('User is not confirmed.')) {
    toast.error('Please confirm your account with the e-mail that was sent to you.');
  } else if (e.message.includes(' Incorrect username or password.')) {
    toast.error('Incorrect username or password.');
  } else if (e.message.includes('User does not exist.')) {
    toast.error('Incorrect username or password.');
  } else {
    toast.error(
      <div>
        <div>Unexpected Error. Please try again in a few moments</div>
        {e.message ? <pre>{e.message}</pre> : null}
      </div>
    );
  }
  console.error(e);
  return;
};

const AuthRegister = () => {
  const [loading] = useState(false);
  const [isSecondStage, setSecondStage] = useState(false);
  const [username, setUsername] = useState(false);
  const [savedValues, setSavedValues] = useState({});
  const appContext = useContext(AppContext);
  const history = useHistory();

  const getSecurityCode = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      await Auth.signUp({
        username: values.username,
        password: values.password,
        attributes: {
          given_name: values.firstName,
          family_name: values.lastName
        }
      });

      setSavedValues({
        email: values.username,
        password: values.password,
        firstName: values.firstName,
        lastName: values.lastName,
        name: values.firstName + ' ' + values.lastName
      });
      setUsername(values.username);
      setSecondStage(true);
    } catch (e) {
      errorOut(e);
      setSubmitting(false);
      setSecondStage(false);
      return;
    }
  };

  const confirmUser = async (values, { setSubmitting }) => {
    setSubmitting(true);

    try {
      const result = await Auth.confirmSignUp(username, values?.code);

      console.log(result);
      toast.success('Account confirmed. You can now login.');

      const awsUser = await appContext.logInAws(values.username, savedValues?.password);

      console.log(awsUser);

      const newUser = {
        ...savedValues,
        password: null,
        id: awsUser?.attributes?.sub
      };
      console.log(newUser);

      const response = await postUser(newUser);

      console.log(response);

      setSubmitting(false);
      history.push(loginRoute);
    } catch (e) {
      errorOut(e);
      setSubmitting(false);
      return;
    }
  };

  const validateFirstForm = (values) => {
    const errors = {};

    if (!values.username) {
      errors.username = 'Mandatory';
    }

    if (!values.firstName) {
      errors.firstName = 'Mandatory';
    }

    if (!values.lastName) {
      errors.lastName = 'Mandatory';
    }

    if (!values.passwordConfirm) {
      errors.passwordConfirm = 'Mandatory';
    }

    if (values.passwordConfirm !== values.password) {
      errors.passwordConfirm = 'Passwords do not match';
    }

    const password = values.password;
    const hasNumber = /\d/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasUppercase = /[A-Z]/.test(password);
    const hasWhitespace = /\s/.test(password);

    if (hasWhitespace) {
      errors.password = 'Space characters not allowed';
    }

    if (!password || password?.length < 8) {
      errors.password = 'Minimum 8 characters';
    }

    if (!hasNumber) {
      errors.password = 'Must contain a number';
    }

    if (!hasUppercase) {
      errors.password = 'Must contain a lowercase character';
    }

    if (!hasLowercase) {
      errors.password = 'Must contain an uppercase character';
    }
    if (!values.password) {
      errors.password = 'Mandatory';
    }

    return errors;
  };

  const validateSecondForm = (values) => {
    const errors = {};

    if (!values.username) {
      errors.username = 'Mandatory';
    }

    if (!values.code) {
      errors.code = 'Mandatory';
    }

    return errors;
  };

  return (
    <div id="register" className={`w-full flex justify-start items-center pb-4`}>
      <div className="w-full h-auto flex justify-center items-center">
        {!isSecondStage ? (
          <Formik
            id="formik-register"
            className="w-full"
            initialValues={{ username: '', password: '' }}
            validate={validateFirstForm}
            onSubmit={getSecurityCode}
          >
            {({ isSubmitting, setSubmitting }) => (
              <Form
                id="form-register"
                className="form-register w-full h-full flex flex-col justify-start items-center"
              >
                {!!loading && (
                  <Ring
                    className="max-w-72 w-auto flex justify-center items-end"
                    size={40}
                    scale={0.5}
                  ></Ring>
                )}
                <div className="w-full flex flex-col">
                  <div className="w-full mb-4 flex flex-col justify-start items-start">
                    <Field
                      name="username"
                      placeholder="Enter Email"
                      className="w-full rounded-lg py-4 px-6 mb-4 text-gray-800 bg-gray-200 focus:bg-gray-100 leading-tight appearance-none focus:outline-none"
                    />
                    <ErrorMessage
                      className="text-red-700 self-end"
                      name="username"
                      component="div"
                    />
                  </div>
                  <div className="w-full mb-4 flex flex-col justify-start items-start">
                    <Field
                      name="firstName"
                      placeholder="First Name"
                      className="w-full rounded-lg py-4 px-6 mb-4 text-gray-800 bg-gray-200 focus:bg-gray-100 leading-tight appearance-none focus:outline-none"
                    />
                    <ErrorMessage
                      className="text-red-700 self-end"
                      name="firstName"
                      component="div"
                    />
                  </div>
                  <div className="w-full mb-4 flex flex-col justify-start items-start">
                    <Field
                      name="lastName"
                      placeholder="Last Name"
                      className="w-full rounded-lg py-4 px-6 mb-4 text-gray-800 bg-gray-200 focus:bg-gray-100 leading-tight appearance-none focus:outline-none"
                    />
                    <ErrorMessage
                      className="text-red-700 self-end"
                      name="lastName"
                      component="div"
                    />
                  </div>

                  <div className="w-full mb-4 flex flex-col justify-start items-start">
                    <Field
                      type="password"
                      name="password"
                      placeholder="New password"
                      className="w-full rounded-lg py-4 px-6 mb-4 text-gray-800 bg-gray-200 focus:bg-gray-100 leading-tight appearance-none focus:outline-none"
                    />
                    <ErrorMessage
                      className="text-red-700 self-end"
                      name="password"
                      component="div"
                    />
                  </div>

                  <div className="mb-6 flex flex-col justify-start items-start">
                    <Field
                      type="password"
                      name="passwordConfirm"
                      placeholder="Confirm Password"
                      className="w-full rounded-lg py-4 px-6 mb-4 text-gray-800 bg-gray-200 focus:bg-gray-100 leading-tight appearance-none focus:outline-none"
                    />
                    <ErrorMessage
                      className="text-red-700 self-end"
                      name="passwordConfirm"
                      component="div"
                    />
                  </div>

                  <div className="flex items-center justify-between">
                    <button
                      type="button"
                      className="inline-block align-baseline px-3 py-2 rounded-md bg-gray-100 hover:bg-gray-200 font-light text-gray-800 hover:text-gray-600 mr-12"
                      disabled={isSubmitting || loading}
                      onClick={() => {
                        history.push('/login');
                      }}
                    >
                      Back to Login
                    </button>
                    <button
                      type="submit"
                      className="min-w-1/2 bg-finblue hover:bg-blue-800 px-3 py-2 text-white font-thin tracking-wider uppercase rounded-lg focus:outline-none focus:shadow-outline"
                      disabled={isSubmitting || loading}
                    >
                      Continue
                    </button>
                  </div>
                  {/* <div className="flex items-center justify-between pt-4">
                    <button
                    type="button"
                      className="inline-block align-baseline px-3 py-2 rounded-md bg-gray-100 hover:bg-gray-200 font-light text-gray-800 hover:text-gray-600"
                      disabled={isSubmitting || loading || !values?.username}
                      onClick={async () => {
                        console.log(username)
                        getSecurityCode({ username: values?.username }, { setSubmitting });
                      }}
                    >
                      Resend Confirmation Code
                    </button>
                  </div> */}
                </div>
              </Form>
            )}
          </Formik>
        ) : (
          <Formik
            id="formik-confirm"
            enableReinitialize={true}
            className="w-full"
            initialValues={{ username: username, code: '' }}
            validate={validateSecondForm}
            onSubmit={confirmUser}
          >
            {({ isSubmitting, setSubmitting }) => (
              <Form
                id="form-confirm"
                className="form-register w-full h-full flex flex-col justify-start items-center"
              >
                {!!loading && (
                  <Ring
                    className="max-w-72 w-auto flex justify-center items-end"
                    size={40}
                    scale={0.5}
                  ></Ring>
                )}
                <div className="w-full flex flex-col">
                  <div className="w-full mb-8 flex flex-col justify-start items-start">
                    We have sent you an e-mail with a verification code. Please check your inbox and
                    enter the verification code:
                  </div>
                  <div className="w-full mb-4 flex flex-col justify-start items-start">
                    <Field
                      name="username"
                      placeholder="Enter Email"
                      className="w-full rounded-lg py-4 px-6 mb-4 text-gray-800 bg-gray-200 focus:bg-gray-100 leading-tight appearance-none focus:outline-none"
                    />
                    <ErrorMessage
                      className="text-red-700 self-end"
                      name="username"
                      component="div"
                    />
                  </div>

                  <div className="w-full mb-4 flex flex-col justify-start items-start">
                    <Field
                      name="code"
                      placeholder="Verification Code"
                      autocomplete="off"
                      className="w-full rounded-lg py-4 px-6 mb-4 text-gray-800 bg-gray-200 focus:bg-gray-100 leading-tight appearance-none focus:outline-none"
                    />
                    <ErrorMessage className="text-red-700 self-end" name="code" component="div" />
                  </div>
                  <div className="flex items-center justify-between pb-4">
                    <button
                      type="submit"
                      className="w-full bg-finblue hover:bg-blue-800 text-white font-thin tracking-wider uppercase px-3 py-2  rounded-lg focus:outline-none focus:shadow-outline"
                      disabled={isSubmitting || loading}
                    >
                      Confirm Account
                    </button>
                  </div>
                  <div className="flex items-center justify-between">
                    <button
                      type="button"
                      className="inline-block align-baseline px-3 py-2 rounded-md bg-gray-100 hover:bg-gray-200 font-light text-gray-800 hover:text-gray-600 mr-4"
                      disabled={isSubmitting || loading}
                      onClick={() => getSecurityCode({ username }, { setSubmitting })}
                    >
                      Resend Code
                    </button>
                    <button
                      type="button"
                      className="inline-block align-baseline px-3 py-2 rounded-md bg-gray-100 hover:bg-gray-200 font-light text-gray-800 hover:text-gray-600"
                      disabled={isSubmitting || loading}
                      onClick={() => {
                        history.push('/login');
                      }}
                    >
                      Back to Login
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </div>
  );
};

export default AuthRegister;
