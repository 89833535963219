import React, { Component } from 'react';
import autobind from 'auto-bind';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';

import AppContext from './context/AppContextBase';
import { i18n, i18nInit } from './i18n';
import loadResources from './i18n/loadResources';
import routes from './routes/public';
import PublicRoute from './components/RoutePublic';
import ProtectedRoute from './components/RouteProtected';
import MetaTags from './components/MetaTags';
//import Lost from './components/Lost';

import logoBig from './img/logo_big.svg';
import './App.scss';
import '@fontsource/poppins';

import ReactGA from 'react-ga';
ReactGA.initialize('UA-218070531-1');
class App extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      i18nLoaded: false,
      isLoading: false
      //password: null
    };

    autobind(this);
  }

  async componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);

    await i18nInit;
    await loadResources(i18n);

    this.setState({ i18nLoaded: true });
  }

  render() {
    return (
      <>
        <MetaTags
          title={'Finpartner'}
          description={'Finpartner Web App'}
          imageSrc={logoBig}
          url={window.location.href}
        ></MetaTags>

        <Router>
          <div
            className={
              'flex flex-col justify-start items-center min-h-screen h-screen w-screen overflow-x-hidden overflow-y-scroll'
            }
          >
            <AppLoading isLoading={!(this.state.i18nLoaded && this.context.initLoaded)}>
              <Switch>
                {routes?.map((route) => {
                  if (!route.protected) {
                    return (
                      <PublicRoute
                        key={route.path}
                        exact={route.exact}
                        path={route.path}
                        component={route.component}
                        render={route.render}
                      ></PublicRoute>
                    );
                  } else {
                    return (
                      <ProtectedRoute
                        authenticated={this.context.state.authenticated}
                        key={route.path}
                        exact={route.exact}
                        path={route.path}
                        component={route.component}
                        render={route.render}
                      ></ProtectedRoute>
                    );
                  }
                })}
                <PublicRoute render={() => <Redirect to="/"></Redirect>} />
              </Switch>
            </AppLoading>
          </div>
        </Router>
        <ToastContainer
          position="top-right"
          autoClose={4000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </>
    );
  }
}

const AppLoading = ({ isLoading, children }) => {
  return (
    <>
      {isLoading ? <div className={'loader'}></div> : null}
      {children}
    </>
  );
};

App.contextType = AppContext;

export default App;
