// eslint-disable-next-line no-unused-vars
import { backendAddress } from '../common/variables';
// eslint-disable-next-line no-unused-vars
import { handleResponse, authenticate, errors } from './common';

export async function getGroup(_id) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const target = 'groups/' + _id;

  const response = await fetch(backendAddress + target, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
}
