import { RefreshIcon } from '@heroicons/react/outline';
import React, { useEffect, useState } from 'react';
import Footer from '../../components/Footer';
import BlueHeader from '../../components/Header/BlueHeader';
import { getNifRequests } from '../../network/nifRequests';
import PaymentInfo from './PaymentInfo';
import investmentTypes from './investmentTypes';

export default function NifRequestList() {
  //const [uploading, setUploading] = useState(false);

  const [nifList, setNifList] = useState([]);
  const [uploading, setUploading] = useState(true);

  //const { t } = useTranslation();

  //React.useEffect(() => {}, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getMyApplications();
  }, []);

  async function getMyApplications() {
    setUploading(true);
    let response = await getNifRequests();

    if (response.ok) {
      // console.log('myApplications', response);
      setNifList(response.data);
    }

    setUploading(false);
  }

  return (
    <div className="w-full h-full flex flex-col justify-between items-center bg-gray-100  overflow-scroll">
      <BlueHeader title="Account" subtitle="In the palm of your hand"></BlueHeader>
      <ListContainer />
      <Footer />
    </div>
  );

  function ListContainer() {
    return (
      <div
        id="nifrequest"
        className="w-full h-full justify-center items-center flex-wrap flex flex-row p-6  bg-gray-100"
      >
        <div className="flex w-6/7  h-100  p-6 flex-col  rounded-3xl  justify-between items-center ">
          <ListHeader />

          <RequestList />
        </div>
      </div>
    );
  }

  function ListHeader() {
    return (
      <div className="flex w-full h-auto flex-row  justify-between">
        <div>
          <div className="text-5xl font-bold text-finblue ">Your NIF applications:</div>
          {uploading && (
            <RefreshIcon
              className={`text-cyan-600 stroke-2 w-20 h-10 px-4 animate-reverse-spin`}
            ></RefreshIcon>
          )}
        </div>
        <div className="p-4 bg-white rounded-md">
          <PaymentInfo />
        </div>
      </div>
    );
  }

  function RequestList() {
    return (
      <div className="flex flex-col w-full h-auto  my-4 ">
        {nifList.length ? (
          nifList.map((nifRequest, index) => (
            <NifApplication nifRequest={nifRequest} key={index} index={index} />
          ))
        ) : (
          <NoApplications />
        )}
      </div>
    );
  }

  function NifApplication({ nifRequest, index }) {
    const investmentType = investmentTypes.find((x) => x.key === nifRequest?.investmentType) ?? {
      key: 'default'
    };
    console.log(investmentType);
    return (
      <div
        className={`flex flex-col w-full h-auto p-6 mt-4 ${
          nifRequest.processFinished ? ' bg-fingreen ' : ' bg-white '
        } rounded-lg   font-bold hover:shadow-md justify-start items-start text-xl`}
      >
        <div className="flex flex-row w-full justify-between">
          <div className="text-4xl font-bold pb-4 w-1/2 ">Application {index + 1}</div>{' '}
          <div className="text-3xl font-bold pb-4 w-1/2 ">
            Request ID: {' ' + nifRequest.uniqueId}
          </div>
        </div>
        <div className="flex w-full justify-between py-2 ">
          <div className="w-1/2"> {nifRequest.fullName}</div>{' '}
          <div className="w-1/2"> Address: {nifRequest.fullAddress}</div>
        </div>
        <div className="flex w-full justify-between py-2">
          <div className="w-1/2"> {nifRequest.email}</div>{' '}
          <div className="w-1/2"> Files: {nifRequest.documents.length}</div>
        </div>
        <div className="flex w-full justify-between py-2">
          <div className="w-1/2">Id Card Number: {nifRequest.idCard}</div>{' '}
          <div className="w-1/2">Wechat: {nifRequest.wechat}</div>
        </div>
        {!!investmentType.title && (
          <div className="flex w-full justify-between py-2">
            <div className="w-1/2">Investment type: {investmentType.title}</div>{' '}
          </div>
        )}
        <div className=" w-full border-t border-gray-100 mt-4"></div>
        <div className="flex w-full pt-6">
          <div className="flex flex-row w-1/2">
            Application Paid?
            {nifRequest.paid ? (
              <div className="pl-1 text-sky-500">Yes</div>
            ) : (
              <div className="pl-1 text-red-700">
                {' '}
                {' No.  Awaiting  payment' +
                  (investmentType.price ? ' - ' + investmentType.price + '€' : '')}
              </div>
            )}
          </div>
          <div className="w-1/2">Process Finished? {nifRequest.processFinished ? 'Yes' : 'No'}</div>
        </div>
        <div className=" w-full border-t border-gray-100 mt-4"></div>
        <div className="flex  w-full h-auto text-center py-2">
          <div> Finpartner Comments: {nifRequest.finpartnerComments} </div>
        </div>
      </div>
    );
  }

  function NoApplications() {
    return (
      <div
        className={`flex flex-col w-full h-auto py-6 m-4  bg-white 
     rounded-lg  font-bold hover:shadow-md justify-center items-center text-2xl`}
      >
        <div className="text-4xl font-bold py-6 ">No applications Submited</div>
      </div>
    );
  }
}
