import moment from 'moment';
import deburr from 'lodash/deburr';
import cloneDeep from 'lodash/cloneDeep';

export const tryParseDate = (string, options = {}) => {
  const formats = [
    'M-D-YYYY',
    'MM-DD-YYYY',
    'DD-MM-YYYY',
    'D/MM/YYYY',
    'DD/MM/YY',
    'M-D-YY',
    'DD-MM-YY',
    'D/MM/YY',
    'DD/MM/YY'
  ];
  let result;
  for (let format of formats) {
    result = moment(string, format);
    if (result.isValid()) {
      return result;
    }
  }
  if (options.nullable) {
    return null;
  }
  return moment('invalid date');
};

export const handleRequest = (request, erroMessage) => {
  if (!request || request.result !== 'OK' || !request.data) {
    throw request;
  }
  return request;
};

export const anchorDownload = (blob, filename) => {
  const a = document.createElement('a');
  a.download = filename;
  a.href = URL.createObjectURL(blob);
  a.style.display = 'none';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  setTimeout(function () {
    URL.revokeObjectURL(a.href);
  }, 1500);
};

export const traverseObject = (object, fn) => {
  for (const key in object) {
    fn.apply(this, [key, object[key]]);
    if (object[key] !== null && typeof object[key] == 'object') {
      traverseObject(object[key], fn);
    }
  }
};

export const traverseArrayImmutable = (array, fn) => {
  const result = [];
  for (const object of array) {
    const copy = JSON.parse(JSON.stringify(object));
    result.push(copy);
    fn.apply(this, [copy]);

    if (copy !== null && typeof copy === 'object') {
      for (const key in copy) {
        if (Array.isArray(copy[key])) {
          traverseArrayImmutable(copy[key], fn);
        }
      }
    }
  }
  return result;
};

export const createOnSave = (onSave, path) => {
  return async (value) => {
    await onSave(path, value);
  };
};

export const generateSlug = (value, charLimit = 0) => {
  if (!value) {
    return '';
  }
  let result = deburr(value)
    .replace(/^[\d.]+/, '')
    .replace(/\s*-\s*/g, '-')
    .replace(/ +/g, ' ')
    .replace(/ /g, '-')
    .replace(/[^-_[:alnum:]]/g, '')
    .toLowerCase();

  if (charLimit > 0) {
    result = result.substring(0, charLimit);
  }

  return result;
};

export const cleanValue = (value) => {
  const toClean = typeof value === 'string' ? value : JSON.stringify(Object.values(value));
  return deburr(toClean).toLowerCase();
};

export const isEnter = (event) => event.key === 'Enter';

export const selectNodeText = (domElementOrReactRef) => {
  const target = domElementOrReactRef?.current ?? domElementOrReactRef;

  if (!target && !target?.current) {
    return;
  }
  let range, selection;
  if (document.body.createTextRange) {
    range = document.body.createTextRange();
    range.moveToElementText(target);
    range.select();
  } else if (window.getSelection) {
    selection = window.getSelection();
    range = document.createRange();
    range.selectNodeContents(target);
    selection.removeAllRanges();
    selection.addRange(range);
  }
};

export const isNullish = (value) => {
  return value === null || typeof value === 'undefined';
};

export const arrayMoveTo = (array, from, to) => {
  const clone = cloneDeep(array);
  clone.splice(to, 0, clone.splice(from, 1)[0]);
  console.log({ array, from, to, clone });
  return clone;
};

export const matchSearch = (search, object) => {
  return (
    deburr(JSON.stringify(Object.values(object)))
      .toLowerCase()
      .indexOf(deburr(search ?? '').toLowerCase()) !== -1
  );
};

export function makeId(length) {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
