import Home from '../pagesPublic/Home';
import AdminHome from '../pagesAdmin/Home';

const routes = [
  {
    label: 'Finpartner',
    path: '/',
    exact: true,
    component: Home
  },
  {
    label: 'App',
    protected: true,
    path: '/app',
    exact: false,
    component: AdminHome
  },
  {
    label: 'Login',
    noMenu: true,
    path: '/login',
    exact: false,
    component: Home
  },
  {
    label: 'Recover Password',
    noMenu: true,
    path: '/recover',
    exact: false,
    render: () => (
      <Home
        title={
          <>
            Recover <span className="text-finblue">Password</span>
          </>
        }
      ></Home>
    )
  },
  {
    label: 'Register',
    noMenu: true,
    path: '/register',
    exact: false,
    render: () => <Home title={<span className="text-finblue">Register</span>}></Home>
  }
];
export default routes;
