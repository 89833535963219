import React from 'react';
import { Link } from 'react-router-dom';

import './Lost.scss';
const Lost = ({
  title,
  link = '/',
  message = (
    <>
      <div>Nothing here.</div>
      <div className="border-b border-bluegray-500">Take me back.</div>
    </>
  ),
  className = ''
}) => {
  return (
    <Link
      to={link}
      className={`lost flex flex-col justify-center items-center w-1/2 h-auto text-gray-500 ${className}`}
    >
      {/* <div className="flex justify-center items-center w-full">
        <Octopus className="octopus object-contain h-full w-auto text-white"></Octopus>
      </div> */}
      {!!title && <h1 className="text-gray-500">Lost?</h1>}
      {!!message && (
        <div className="flex flex-col justify-center items-center text-gray-500">
          {message}
        </div>
      )}
    </Link>
  );
};

export default Lost;
