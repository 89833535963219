import React from 'react';
import Select from 'react-select';
import sort from 'fast-sort';
import { documentTags } from '../common/variables';

const TagSelector = ({ className = '', tagValueArray, onChange = (tagValueArray) => {} }) => {
  const [docTags, setDocTags] = React.useState(tagValueArray ?? []);

  const onChangeLocal = (selectedOptions, metaAction) => {
    const tagValues = selectedOptions?.map((tagOption) => tagOption.value) ?? [];

    onChange(tagValues);

    setDocTags(tagValues);
  };

  const currentOptions = documentTags.filter((tagObj) => docTags?.includes(tagObj.value));

  return (
    <Select
      className={`flex-grow h-full bg-white border rounded-xl px-2 py-0 border-gray-300 ${className}`}
      closeMenuOnSelect={false}
      value={sort(currentOptions).desc((t) => t.label)}
      isMulti
      hideSelectedOptions={true}
      //menuIsOpen={true}
      placeholder={<span className="text-gray-500">Filter by year / tag</span>}
      styles={tagSelectStyles}
      options={sort(documentTags).asc((t) => t.label)}
      onChange={onChangeLocal}
    />
  );
};

const tagSelectStyles = {
  control: (provided, state) => {
    return {
      ...provided,
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
      color: '#000000',
      height: '100%',
      border: '0px',
      minHeight: 'unset',
      width: 'auto',
      borderRadius: 'unset',
      flexWrap: 'nowrap'
    };
  },
  dropdownIndicator: (provided, state) => {
    return {
      ...provided,
      height: '50%',
      width: '50%',
      padding: 0,
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem'
    };
  },
  clearIndicator: (provided, state) => {
    return {
      ...provided,
      padding: 0,
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem'
    };
  },
  placeholder: (provided, state) => {
    return {
      ...provided,
      color: 'rgba(0,0,0, 1)'
    };
  },
  valueContainer: (provided, state) => {
    return {
      ...provided,
      height: '100%',
      padding: '0.25rem',
      display: 'flex',
      width: 'auto',
      minWidth: 0
    };
  },
  multiValue: (provided, state) => {
    return {
      ...provided,
      backgroundColor: 'rgba(43, 51, 133, 1)',
      color: '#ffffff',
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem',
      borderRadius: 12
    };
  },
  multiValueLabel: (provided, state) => {
    return {
      ...provided,
      color: '#ffffff'
    };
  },
  menu: (provided, state) => {
    return {
      ...provided,
      backgroundColor: 'rgba(255, 255, 255)',
      border: '1px solid hsl(0, 0%, 80%);',
      color: '#000000',
      maxHeight: '16rem'
    };
  },
  menuList: (provided, state) => {
    return {
      ...provided,
      backgroundColor: 'rgba(255, 255, 255)',
      color: '#000000',
      maxHeight: '15.8rem'
    };
  },
  option: (provided, state) => {
    return {
      ...provided,
      backgroundColor: 'rgba(255, 255, 255)',
      color: '#000000'
    };
  }
};

export default TagSelector;
