import { Storage } from 'aws-amplify';
import uuidv4 from 'uuid/v4';

import awsconfig from '../context/aws-exports';

const storageConfig = {
  bucket: awsconfig.aws_user_files_s3_bucket,
  region: awsconfig.aws_user_files_s3_bucket_region,
  level: 'public'
};

Storage.configure(storageConfig);

const folderName = 'nifrequests/';

const customPrefix = {
  public: folderName
};
const uploadFile = async (file) => {
  const random = uuidv4();
  const fileID = random + '_' + file.name;

  let s3Result;
  try {
    s3Result = await Storage.put(fileID, file, {
      contentType: file.type,
      //level: 'public',
      customPrefix: {
        public: 'nifrequests/'
      }
      //  ACL: 'public-read'
    });
  } catch (error) {
    return { ok: false, data: s3Result, error };
  }

  return { ok: true, data: s3Result };
};

const downloadFile = async (s3Key) => {
  const href = await Storage.get(s3Key, { download: false, customPrefix: customPrefix });

  var evLink = document.createElement('a');
  evLink.href = href;
  evLink.target = '_blank';
  evLink.rel = 'noopener noreferrer';
  document.body.appendChild(evLink);
  evLink.click();
  // Now delete it
  evLink.parentNode.removeChild(evLink);
};

const removeFile = async (s3Key) => {
  let s3Result;
  try {
    s3Result = await Storage.remove(s3Key, {
      customPrefix: customPrefix
    });
  } catch (error) {
    return { ok: false, data: s3Result, error };
  }

  return { ok: true, data: s3Result };
};

export { uploadFile, downloadFile, removeFile };
