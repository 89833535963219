const investmentTypes = [
  {
    key: 'investment_portugal',
    title: 'With tax representative',
    description: (
      <div>
        If you are a resident of a non-EU country and intend to invest or start the process of
        applying for residency in Portugal, you should know that the first step is to request a tax
        identification number and appoint a fiscal representative.
        <br />
        <br /> The tax representative is responsible for making the necessary communication on your
        behalf to the tax authorities.
      </div>
    ),
    price: 390
  },
  {
    key: 'investment_abroad',
    title: 'Without tax representative',
    description: (
      <div>
        If you are a non-resident in Portugal and want to get a Portuguese tax identification
        number, you can get it here!
        <br />
        <br />
        Note that in Portugal you need a Portuguese tax identification number (NIF) to, for example,
        open a bank account in Portugal, subscribe to a mobile network contract among others.
      </div>
    ),
    price: 150
  }
];

export default investmentTypes;
