import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import './Recover.scss';
import Ring from '../Ring';

const loginRoute = '/login';

const errorOut = async (e) => {
  if (e.message.includes('User is not confirmed.')) {
    toast.error('Please confirm your account with the e-mail that was sent to you.');
  } else if (e.message.includes(' Incorrect username or password.')) {
    toast.error('Incorrect username or password.');
  } else if (e.message.includes('User does not exist.')) {
    toast.error('Incorrect username or password.');
  } else {
    toast.error(
      <div>
        <div>Unexpected Error. Please try again in a few moments</div>
        {e.message ? <pre>{e.message}</pre> : null}
      </div>
    );
  }
  console.error(e);
  return;
};

const AuthRecover = () => {
  const [loading] = useState(false);
  const [isSecondStage, setSecondStage] = useState(false);
  const [username, setUsername] = useState(false);
  const history = useHistory();

  const getSecurityCode = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      await Auth.forgotPassword(values?.username);
      setUsername(values?.username);
      setSecondStage(true);
    } catch (e) {
      errorOut(e);
      setSubmitting(false);
      setSecondStage(false);
      return;
    }
  };

  const recoverPassword = async (values, { setSubmitting }) => {
    setSubmitting(true);

    try {
      const result = await Auth.forgotPasswordSubmit(
        values?.username,
        values?.code,
        values?.password
      );
      console.log(result);
      toast.success('Password changed successfully');

      setSubmitting(false);
      history.push(loginRoute);
    } catch (e) {
      errorOut(e);
      setSubmitting(false);
      return;
    }
  };

  const validateFirstForm = (values) => {
    const errors = {};

    if (!values.username) {
      errors.username = 'Mandatory';
    }

    return errors;
  };

  const validateSecondForm = (values) => {
    const errors = {};

    if (!values.username) {
      errors.username = 'Mandatory';
    }

    if (!values.code) {
      errors.code = 'Mandatory';
    }

    if (!values.passwordConfirm) {
      errors.passwordConfirm = 'Mandatory';
    }

    if (!values.passwordConfirm === values.password) {
      errors.passwordConfirm = 'Passwords do not match';
    }

    const password = values.password;
    const hasNumber = /\d/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasUppercase = /[A-Z]/.test(password);
    const hasWhitespace = /\s/.test(password);

    if (hasWhitespace) {
      errors.password = 'Space characters not allowed';
    }

    if (!password || password?.length < 8) {
      errors.password = 'Minimum 8 characters';
    }

    if (!hasNumber) {
      errors.password = 'Must contain a number';
    }

    if (!hasUppercase) {
      errors.password = 'Must contain a lowercase character';
    }

    if (!hasLowercase) {
      errors.password = 'Must contain an uppercase character';
    }
    if (!values.password) {
      errors.password = 'Mandatory';
    }

    return errors;
  };

  return (
    <div id="login" className={`w-full flex justify-start items-center pb-4`}>
      <div className="w-full h-auto flex justify-center items-center">
        {!isSecondStage ? (
          <Formik
            className="w-full"
            initialValues={{ username: '', password: '' }}
            validate={validateFirstForm}
            onSubmit={getSecurityCode}
          >
            {({ isSubmitting }) => (
              <Form className="login-form w-full h-full flex flex-col justify-start items-center">
                {!!loading && (
                  <Ring
                    className="max-w-72 w-auto flex justify-center items-end"
                    size={40}
                    scale={0.5}
                  ></Ring>
                )}
                <div className="w-full flex flex-col">
                  <div className="w-full mb-4 flex flex-col justify-start items-start">
                    <Field
                      name="username"
                      placeholder="Enter Email"
                      className="w-full rounded-lg py-4 px-6 mb-4 text-gray-800 bg-gray-200 focus:bg-gray-100 leading-tight appearance-none focus:outline-none"
                    />
                    <ErrorMessage
                      className="text-red-700 self-end"
                      name="username"
                      component="div"
                    />
                  </div>

                  <div className="flex items-center justify-between">
                    <button
                      className="inline-block align-baseline px-3 py-2 rounded-md bg-gray-100 hover:bg-gray-200 font-light text-gray-800 hover:text-gray-600 mr-12"
                      disabled={isSubmitting || loading}
                      onClick={() => {
                        history.push('/login');
                      }}
                    >
                      Back to Login
                    </button>
                    <button
                      type="submit"
                      className="min-w-1/2 bg-finblue hover:bg-blue-800 px-3 py-2 text-white font-thin tracking-wider uppercase rounded-lg focus:outline-none focus:shadow-outline"
                      disabled={isSubmitting || loading}
                    >
                      Get Confirmation Code
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        ) : (
          <Formik
            className="w-full"
            initialValues={{ username: username, password: '', code: '' }}
            validate={validateSecondForm}
            onSubmit={recoverPassword}
          >
            {({ isSubmitting, setSubmitting }) => (
              <Form className="login-form w-full h-full flex flex-col justify-start items-center">
                {!!loading && (
                  <Ring
                    className="max-w-72 w-auto flex justify-center items-end"
                    size={40}
                    scale={0.5}
                  ></Ring>
                )}
                <div className="w-full flex flex-col">
                  <div className="w-full mb-4 flex flex-col justify-start items-start">
                    <Field
                      name="username"
                      placeholder="Enter Email"
                      className="w-full rounded-lg py-4 px-6 mb-4 text-gray-800 bg-gray-200 focus:bg-gray-100 leading-tight appearance-none focus:outline-none"
                    />
                    <ErrorMessage
                      className="text-red-700 self-end"
                      name="username"
                      component="div"
                    />
                  </div>

                  <div className="w-full mb-4 flex flex-col justify-start items-start">
                    <Field
                      name="code"
                      placeholder="Security Code"
                      className="w-full rounded-lg py-4 px-6 mb-4 text-gray-800 bg-gray-200 focus:bg-gray-100 leading-tight appearance-none focus:outline-none"
                    />
                    <ErrorMessage className="text-red-700 self-end" name="code" component="div" />
                  </div>

                  <div className="w-full mb-4 flex flex-col justify-start items-start">
                    <Field
                      type="password"
                      name="password"
                      placeholder="New password"
                      className="w-full rounded-lg py-4 px-6 mb-4 text-gray-800 bg-gray-200 focus:bg-gray-100 leading-tight appearance-none focus:outline-none"
                    />
                    <ErrorMessage
                      className="text-red-700 self-end"
                      name="password"
                      component="div"
                    />
                  </div>

                  <div className="mb-6 flex flex-col justify-start items-start">
                    <Field
                      type="password"
                      name="passwordConfirm"
                      placeholder="Confirm Password"
                      className="w-full rounded-lg py-4 px-6 mb-4 text-gray-800 bg-gray-200 focus:bg-gray-100 leading-tight appearance-none focus:outline-none"
                    />
                    <ErrorMessage
                      className="text-red-700 self-end"
                      name="passwordConfirm"
                      component="div"
                    />
                  </div>
                  <div className="flex items-center justify-between pb-4">
                    <button
                      type="submit"
                      className="w-full bg-finblue hover:bg-blue-800 text-white font-thin tracking-wider uppercase px-3 py-2  rounded-lg focus:outline-none focus:shadow-outline"
                      disabled={isSubmitting || loading}
                    >
                      Set new password
                    </button>
                  </div>
                  <div className="flex items-center justify-between">
                    <button
                      className="inline-block align-baseline px-3 py-2 rounded-md bg-gray-100 hover:bg-gray-200 font-light text-gray-800 hover:text-gray-600 mr-4"
                      disabled={isSubmitting || loading}
                      onClick={() => getSecurityCode({ username }, { setSubmitting })}
                    >
                      Resend Code
                    </button>
                    <button
                      className="inline-block align-baseline px-3 py-2 rounded-md bg-gray-100 hover:bg-gray-200 font-light text-gray-800 hover:text-gray-600"
                      disabled={isSubmitting || loading}
                      onClick={() => {
                        history.push('/login');
                      }}
                    >
                      Back to Login
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </div>
  );
};

export default AuthRecover;
