import SettingsAccount from '../pagesAdmin/Settings/SettingsAccount';

const routes = [
  {
    label: 'Account',
    path: `/app/settings/account`,
    component: SettingsAccount,
    exact: true
  }
];
export default routes;
