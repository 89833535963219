import React from 'react';
import LanguageButton from '../LanguageButton';

import LanguageContext from '../../context/LanguageContextBase';

import { languages } from '../../context/LanguageContext';

import ptFlag from '../../img/pt.svg';
import enFlag from '../../img/en.svg';
import esFlag from '../../img/es.svg';
import zhFlag from '../../img/zh.svg';
import frFlag from '../../img/fr.svg';

const LanguageBar = ({ className = '' }) => {
  const languageContext = React.useContext(LanguageContext);

  const highlightCurrent = (lang) =>
    languageContext.language === lang ? 'opacity-100' : 'opacity-40';
  return (
    <div className={`flex ${className}`}>
      <LanguageButton
        onClick={() => languageContext.setLanguage(languages.pt.shortCode)}
        className={`h-10 w-10 mr-2 ${highlightCurrent(languages.pt.shortCode)}`}
        lang={languages.pt.shortCode}
        imageSrc={ptFlag}
      ></LanguageButton>
      <LanguageButton
        onClick={() => languageContext.setLanguage(languages.en.shortCode)}
        className={`h-10 w-10 mr-2 ${highlightCurrent(languages.en.shortCode)}`}
        lang={languages.en.shortCode}
        imageSrc={enFlag}
      ></LanguageButton>
      <LanguageButton
        onClick={() => languageContext.setLanguage(languages.fr.shortCode)}
        className={`h-10 w-10 mr-2 ${highlightCurrent(languages.fr.shortCode)}`}
        lang={languages.fr.shortCode}
        imageSrc={frFlag}
      ></LanguageButton>
      <LanguageButton
        onClick={() => languageContext.setLanguage(languages.es.shortCode)}
        className={`h-10 w-10 mr-2 ${highlightCurrent(languages.es.shortCode)}`}
        lang={languages.es.shortCode}
        imageSrc={esFlag}
      ></LanguageButton>
      <LanguageButton
        onClick={() => languageContext.setLanguage(languages.zh.shortCode)}
        className={`h-10 w-10 mr-2 ${highlightCurrent(languages.zh.shortCode)}`}
        lang={languages.zh.shortCode}
        imageSrc={zhFlag}
      ></LanguageButton>
    </div>
  );
};

export default LanguageBar;
