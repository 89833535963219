import React from 'react';

import './Footer.scss';

const Footer = ({ className = '' }) => {
  return (
    <div
      id="footer"
      className={`w-full flex justify-center items-center  min-h-16 h-1/12 ${className}`}
    >
      <div className="px-4 border-r border-finblue">
        <a
          className="text-finblue transform transition hover:scale-105"
          href="https://www.finpartner.pt/"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.finpartner.pt
        </a>
      </div>
      <div className="px-4 border-r border-finblue">
        <a
          className="text-finblue transform transition hover:scale-105"
          href="mailto:finpartner@finpartner.pt"
          target="_blank"
          rel="noopener noreferrer"
        >
          finpartner@finpartner.pt
        </a>
      </div>
      <div className="px-4 ">
        <a
          className="text-finblue transform transition hover:scale-105"
          href="tel:+351210995932"
          target="_blank"
          rel="noopener noreferrer"
        >
          (+351) 21 099 5932
        </a>
      </div>
    </div>
  );
};

export default Footer;
