import { ArrowLeftIcon, CheckCircleIcon, RefreshIcon, XCircleIcon } from '@heroicons/react/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import isNil from 'lodash/isNil';
//import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { removeFile as s3Remove, uploadFile as s3Upload } from '../../common/s3_nif';
import Footer from '../../components/Footer';
import BlueHeader from '../../components/Header/BlueHeader';
import { ReactComponent as UploadNifIcon } from '../../img/nifrequest/uploadIcon.svg';
import fpPic from '../../img/nifrequest/pic_fp.png';
import { postNifRequests } from './../../network/nifRequests';
import PaymentInfo from './PaymentInfo';
import { makeId } from '../../common/util';
import investmentTypes from './investmentTypes';

export default function RequestNifForm() {
  let formSchema = yup
    .object({
      fullName: yup.string().required(),
      fullAddress: yup.string().required(),
      idCard: yup.string().required(),
      wechat: yup.string(),
      maritalStatus: yup.string().required(),
      email: yup.string().email().required(),
      telephone: yup
        .string()
        .matches(/^[0-9,+]*$/)
        .required(),
      termsAndConditions: yup.bool().oneOf([true], '')
    })
    .required();

  const history = useHistory();

  const {
    register,
    handleSubmit,
    //watch,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(formSchema)
  });

  // const [formIsValid, setFormIsValid] = useState(false);
  //const [refreshing, setRefreshing] = useState(false);
  const [uploading, setUploading] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const [fileList, setFileList] = useState([]);

  const [investmentType, setInvestmentType] = React.useState(null);
  const [uniqueId, setUniqueId] = React.useState(null);

  React.useEffect(() => {
    if (!investmentType) {
      history.push('/app/nifrequestform');
    } else {
      history.push('/app/nifrequestform/' + investmentType.key);
    }
  }, [investmentType]); //eslint-disable-line

  React.useEffect(() => {
    if (uniqueId) {
      history.push('/app/nifrequestform/' + investmentType.key + '/' + uniqueId);
    } else {
      if (!investmentType) {
        history.push('/app/nifrequestform');
      } else {
        history.push('/app/nifrequestform/' + investmentType.key);
      }
    }
  }, [uniqueId]); //eslint-disable-line

  async function onSubmit(formInfo) {
    //console.log('formInfo: ', formInfo);

    //build the form with the files to save on DB

    if (!fileList.length) {
      toast.error('Please attach the requested documents.');
      return;
    }

    let documents = fileList.map((file) => ({
      s3Key: file.s3Key,
      originalFileName: file.originalFileName
    }));

    const uniqueId = makeId(10);
    setUniqueId(uniqueId);

    let nifRequestModel = {
      ...formInfo,
      investmentType: investmentType.key,
      uniqueId,
      documents
    };

    //console.log('Final save: ', nifRequestModel);
    //let result =
    await postNifRequests(nifRequestModel);

    setShowModal(true);
    //console.log('result', result);
  }

  //const { t } = useTranslation();

  //React.useEffect(() => {}, []); // eslint-disable-line react-hooks/exhaustive-deps

  const isSecondStep = !isNil(investmentType);

  return (
    <div className="w-full h-auto flex flex-col items-center bg-gray-100 min-h-screen overflow-scroll">
      {showModal && <SucessModal />}
      <BlueHeader title="Account" subtitle="In the palm of your hand"></BlueHeader>
      <FormContainer>
        <div className=" relative flex w-6/7 h-auto p-12 flex-row flex-wrap items-stretch rounded-3xl justify-between ">
          <div className="flex flex-col w-full h-auto">
            <div
              className="relative w-16 h-16 p-1 mb-8 rounded-full border-2 border-finblue cursor-pointer transform transition hover:scale-105"
              onClick={() => {
                if (isSecondStep) {
                  history.push('/app/nifrequestform');
                  setInvestmentType(null);
                } else {
                  history.push('/app/nifrequest');
                }
              }}
            >
              <ArrowLeftIcon className="w-full h-full stroke-current text-finblue stroke-1"></ArrowLeftIcon>
            </div>
            {isSecondStep && <FormHeader className="w-full h-auto" />}
          </div>
          {!isSecondStep ? (
            <>
              {investmentTypes.map((it) => (
                <InvestmentOption
                  {...it}
                  onSelect={() => setInvestmentType(it)}
                  className="w-full pb-8 lg:pb-0 lg:w-1/3"
                />
              ))}

              <div className="w-full lg:w-1/3">
                <img
                  src={fpPic}
                  alt="Hello from Finpartner"
                  className="w-full h-auto object-contain bg-transparent"
                ></img>
              </div>
            </>
          ) : (
            <>
              <FormLeft className="w-full lg:w-5/7 lg:max-w-1/2 min-w-40" />
              <UploadInput className="w-full lg:w-5/7 lg:max-w-1/2 min-w-40 py-6" />
            </>
          )}
        </div>
      </FormContainer>
      <Footer></Footer>
    </div>
  );

  function InvestmentOption({ className = '', title, description, onSelect = () => {} }) {
    return (
      <div className={`relative z-10 flex flex-col overflow-visible pr-16 ${className}`}>
        <div
          className={`relative z-10 flex h-full flex-col font-normal overflow-visible leading-relaxed`}
        >
          <div className="w-full z-0 h-full absolute top-4 left-4 border border-gray-500 rounded-3xl"></div>
          <div className="w-full z-10 text-center bg-finblue text-white py-8 rounded-t-3xl text-3xl">
            {title}
          </div>
          <div className="w-full z-10 flex-1 bg-white rounded-b-3xl p-8 flex flex-col items-center justify-between">
            <div className="w-full pb-16 pt-8 text-xl">{description}</div>
            <button
              onClick={onSelect}
              className={`flex items-center justify-center rounded-full bg-finblue text-white text-lg px-12 py-3 transform transition hover:scale-105`}
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    );
  }

  function FormContainer({ children }) {
    return (
      <div
        id="nifrequest"
        className="w-full h-auto justify-center flex-wrap flex flex-row gap-12 p-6  bg-gray-100"
      >
        {children}
      </div>
    );
  }

  function FormHeader({ className = '' }) {
    return (
      <div className={`flex flex-col ${className}`}>
        <div className="text-4xl font-bold text-finblue">TIN application</div>
        {!isNil(investmentType) && (
          <div className=" py-6 font-light text-gray-600">Required Information</div>
        )}
      </div>
    );
  }

  function FormLeft({ className = '' }) {
    return (
      <div className={`flex flex-col pb-8 lg:pb-0 lg:pr-8 ${className}`}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-wrap bg-white p-8 rounded-3xl"
          autoComplete="off"
        >
          <Field widthClass="w-full" placeholder={'Full Name'} field={'fullName'} />
          <Field widthClass="w-full" placeholder={'Full Current Address'} field={'fullAddress'} />
          <Field placeholder={'ID Card Number'} field={'idCard'} />
          <Field placeholder={'Wechat Id (Optional)'} field={'wechat'} />
          <Field placeholder={'Marital Status'} field={'maritalStatus'} />
          <Field placeholder={'Email'} field={'email'} />
          <Field placeholder={'Telephone Contact'} field={'telephone'} />
          <div
            className="flex flex-row w-1/2 h-14 rounded-full bg-gray-200 my-2 px-6   items-center justify-around "
            key={'termsAndConditions'}
          >
            <div className="text-gray-400">
              I accept the{' '}
              <a
                href="https://www.finpartner.pt/pt/termos-e-condicoes/"
                target="_blank"
                rel="noreferrer"
                className=" underline"
              >
                terms and conditions{' '}
              </a>
            </div>
            <input
              {...register('termsAndConditions')}
              key={'termsAndConditions'}
              type="checkbox"
              className="bg-transparent "
              autoComplete="off"
            />

            <Error field={'termsAndConditions'} errors={errors} />
          </div>
          <SubmitButton />
        </form>
      </div>
    );
  }

  function UploadInput({ className = '' }) {
    return (
      <div
        className={`flex flex-col items-center justify-center bg-white p-8 rounded-3xl ${className}`}
      >
        <label className="flex flex-col items-center justify-center min-w-40 ">
          {uploading ? (
            <RefreshIcon
              className={`text-cyan-600 stroke-2 w-10 h-10 mr-0 md:mr-4 animate-reverse-spin`}
            ></RefreshIcon>
          ) : (
            <UploadNifIcon className={'w-26 h-26 cursor-pointer '} />
          )}
          <div className="text-xl font-bold text-finblue w-full text-center py-6">
            Upload Proof of Address and Certified ID document
          </div>

          <input
            type="file"
            className="hidden"
            onChange={async (e) => {
              if (uploading) {
                console.log('Still uploading, returning');
                return;
              }
              setUploading(true);
              const files = e.target.files;
              if (files?.length > 1) {
                console.error('Multiple files uploaded, using first file');
              }
              const file = files[0];
              //console.log(file);
              const response = await s3Upload(file);
              console.log('uploaded', response);
              try {
                if (response.ok && response?.data?.key) {
                  let updatedfileList = fileList;
                  let newFile = { originalFileName: file.name, s3Key: response.data.key, file };

                  updatedfileList.push(newFile);
                  setFileList(updatedfileList);
                  console.log('fileList: ', fileList);
                } else {
                  throw new Error('Error uploading to backend: request failed');
                }
              } catch (error) {
                console.error('Removing s3 file due to error: ', error);
                console.error(await s3Remove(response.data?.key));
                toast.error('Erro a carregar ficheiro');
              }
              setUploading(false);
            }}
          />
          <div className="flex w-1/2 flex-col">
            {fileList.length ? (
              <div className="text-xl font-bold text-finblue w-full text-center py-6">
                Files Ready:
              </div>
            ) : null}
          </div>
        </label>

        <div className="w-1/2">
          {fileList.map((file) => (
            <div
              className="flex w-full h-14 rounded-full bg-gray-200 my-2 px-6 items-center justify-between "
              key={file.s3Key}
            >
              {file?.originalFileName}{' '}
              <div className="flex flex-row">
                <XCircleIcon
                  className="w-6 h-6 cursor-pointer"
                  onClick={async (e) => {
                    e.stopPropagation();
                    setUploading(true);
                    let response = await s3Remove(file.s3Key);
                    if (response.ok) {
                      //deleted OK
                      let updatedfileList = fileList;
                      updatedfileList = updatedfileList.filter(
                        (fileListElement) => fileListElement.s3Key !== file.s3Key
                      );
                      setFileList(updatedfileList);
                    } else {
                      console.error('Error deleting ', file?.originalFileName);
                    }
                    setUploading(false);
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  function Field({ field, placeholder, widthClass = 'w-1/2' }) {
    return (
      <div
        className={`flex ${widthClass} h-14 rounded-full bg-gray-200 my-2 px-6 items-center justify-center`}
        key={field}
      >
        <input
          {...register(field)}
          key={field}
          type="text"
          placeholder={placeholder}
          className="bg-transparent w-full"
          autoComplete="off"
        />
        <Error field={field} errors={errors} />
      </div>
    );
  }

  function SubmitButton() {
    return (
      <input
        value={'Submit my Request'}
        type="submit"
        className={`flex w-full h-14 items-center justify-center rounded-full bg-finblue text-white text-xl my-6 px-6 cursor-pointer transform transition hover:scale-105`}
      ></input>
    );
  }

  function SucessModal() {
    return (
      <div className="flex absolute top-0 left-0 w-screen h-screen bg-gray-500  bg-opacity-70 z-50 justify-center items-center">
        <div className="flex w-6/7 md:w-1/2 h-auto rounded-lg bg-white p-6 justify-center items-center flex-col">
          <div className="text-2xl font-bold text-finblue w-4/7 text-center py-6">
            Your order has been temporarily accepted, we request payment within 3 business days. You
            can consult your process in the "My Applications" area.
            <div className="py-8">Awaiting Payment: {investmentType?.price}€</div>
            <div className="py-8">
              <PaymentInfo />
            </div>
          </div>
          <Link to={'/app'}>
            <CheckCircleIcon className="w-40 h-40" />
          </Link>
        </div>
      </div>
    );
  }
}

function Error({ field, errors }) {
  //console.log('ield, errors', field, errors);
  let error = errors?.[field] && <div className="rounded-full bg-red-600 w-2 h-2"></div>;

  return error ?? '';
}
